// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: '',
    isLoading: false
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload ?? '';
    },
    clearTokens: (state) => {
      state.accessToken = '';
    },
    setAuthLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    }
  },
  extraReducers (builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      return action.payload;
    });
  }
});

// Action creators are generated for each case reducer function
export const { setAccessToken, clearTokens, setAuthLoading } = authSlice.actions;

export const signIn = createAsyncThunk('auth/signIn', async (payload) => {
  const { post } = useRequest();
  return (await post('auth/signIn', payload))?.data;
});

export const signOut = createAsyncThunk('auth/signOut', async (state) => {
});

export const getAuthIsLoading = ({ auth }) => auth.isLoading;
export const getAccessToken = ({ auth }) => auth.accessToken;
export default authSlice.reducer;
