// @ts-nocheck
import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const collectionEntitySlice = createSlice({
  name: 'collectionEntity',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setCollectionEntityLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    },
    setCollectionEntitySearchResult: (state, action) => {
      state.searchResult = action.payload || {};
    },
  },
});

export const addCollectionEntity: AsyncThunk<boolean> = createAsyncThunk(
  'collectionEntity/add',
  async (payload) => {
    const { postForm } = useRequest();
    const formData = bindFormData(payload);
    return (await postForm('CollectionEntity/Add', formData))?.data;
  }
);

export const updateCollectionEntity: AsyncThunk<boolean> = createAsyncThunk(
  'collectionEntity/update',
  async (payload) => {
    const { postForm } = useRequest();
    const formData = bindFormData(payload);
    console.log('formData on 35', formData.entries());
    return (await postForm('CollectionEntity/Update', formData))?.data;
  }
);

const bindFormData = (payload) => {
  const formData = new FormData();
  formData.append('title', payload.title);
  formData.append('subTitle', payload.subTitle);
  formData.append('collectionType', payload.collectionType);

  if (payload.removeCoverImage !== undefined)
    formData.append('removeCoverImage', payload.removeCoverImage);

  if (payload.description) formData.append('description', payload.description);
  if (payload.externalGroupKey)
    formData.append('externalGroupKey', payload.externalGroupKey);

  if (payload.coverImage?.length > 0)
    formData.append('coverImage', payload.coverImage[0].originFileObj);

  if (payload.id) formData.append('id', payload.id);
  if (payload.releaseDateTicks)
    formData.append('releaseDateTicks', payload.releaseDateTicks);
  if (payload.youtubeId) formData.append('youtubeId', payload.youtubeId);
  if (payload.runtime) formData.append('runtime', payload.runtime);
  if (payload.tagline) formData.append('tagline', payload.tagline);
  if (payload.county) formData.append('county', payload.county);
  if (payload.city) formData.append('city', payload.city);
  if (payload.countryCode)
    formData.append('countryCode', payload.countryCode.toUpperCase());
  if (payload.country) formData.append('country', payload.country);

  if (payload.latitude) formData.append('latitude', payload.latitude);
  if (payload.longitude) formData.append('longitude', payload.longitude);
  if (payload.webSite) formData.append('webSite', payload.webSite);
  if (payload.phoneNumber) formData.append('phoneNumber', payload.phoneNumber);
  if (payload.address) formData.append('address', payload.address);

  if (payload.previewLink) formData.append('previewLink', payload.previewLink);
  if (payload.publishYear) formData.append('publishYear', payload.publishYear);
  if (payload.pageCount) formData.append('pageCount', payload.pageCount);
  if (payload.language) formData.append('language', payload.language);
  if (payload.isPermanentlyClosed)
    formData.append('isPermanentlyClosed', payload.isPermanentlyClosed);

  if (payload.images && Array.isArray(payload.images)) {
    payload.images
      .filter((image) => image.originFileObj)
      .map((image, index) => formData.append('images', image.originFileObj));
  }

  if (payload.removedImages && Array.isArray(payload.removedImages)) {
    payload.removedImages.map((image, index) =>
      formData.append(`removedImages[${index}]`, image.id)
    );
  }

  if (payload.directors && Array.isArray(payload.directors)) {
    payload.directors.map((director, index) =>
      formData.append(`directors[${index}]`, director)
    );
  }

  if (payload.genres && Array.isArray(payload.genres)) {
    payload.genres.map((genre, index) =>
      formData.append(`genres[${index}]`, genre)
    );
  }

  if (payload.authors && Array.isArray(payload.authors)) {
    payload.authors.map((author, index) =>
      formData.append(`authors[${index}]`, author)
    );
  }

  if (payload.placeTypes && Array.isArray(payload.placeTypes)) {
    payload.placeTypes.map((placeType, index) =>
      formData.append(`placeTypes[${index}]`, placeType)
    );
  }

  if (payload.castAndCrews && Array.isArray(payload.castAndCrews)) {
    // eslint-disable-next-line array-callback-return
    payload.castAndCrews.map((castAndCrew, index) => {
      castAndCrew.name &&
        formData.append(`castAndCrews[${index}][name]`, castAndCrew.name);
      castAndCrew.type &&
        formData.append(`castAndCrews[${index}][type]`, castAndCrew.type);
      castAndCrew.image?.fileName &&
        formData.append(
          `castAndCrews[${index}][image][fileName]`,
          castAndCrew.image?.fileName
        );
      castAndCrew.character &&
        formData.append(
          `castAndCrews[${index}][character]`,
          castAndCrew.character
        );
    });
  }

  if (
    payload.entitiesWatchProviders?.flat &&
    Array.isArray(payload.entitiesWatchProviders?.flat)
  ) {
    console.log(127, payload.entitiesWatchProviders?.flat);

    // eslint-disable-next-line array-callback-return
    payload.entitiesWatchProviders.flat.map((wp, index) => {
      wp.name &&
        formData.append(`watchProviderFlatRate[${index}][name]`, wp.name);
      wp.logo &&
        formData.append(`watchProviderFlatRate[${index}][logo]`, wp.logo);
    });
  }

  if (
    payload.entitiesWatchProviders?.rent &&
    Array.isArray(payload.entitiesWatchProviders?.rent)
  ) {
    // eslint-disable-next-line array-callback-return
    payload.entitiesWatchProviders.rent.map((wp, index) => {
      wp.name && formData.append(`watchProviderRent[${index}][name]`, wp.name);
      wp.logo && formData.append(`watchProviderRent[${index}][logo]`, wp.logo);
    });
  }

  if (
    payload.entitiesWatchProviders?.buy &&
    Array.isArray(payload.entitiesWatchProviders?.buy)
  ) {
    // eslint-disable-next-line array-callback-return
    payload.entitiesWatchProviders.buy.map((wp, index) => {
      wp.name && formData.append(`watchProviderBuy[${index}][name]`, wp.name);
      wp.logo && formData.append(`watchProviderBuy[${index}][logo]`, wp.logo);
    });
  }

  return formData;
};

export const searchCollectionEntity = createAsyncThunk(
  'collectionEntity/search',
  async (payload) => {
    const { post } = useRequest();
    return (await post('CollectionEntity/Search', payload))?.data;
  }
);

export const deleteCollectionEntity = createAsyncThunk(
  'collectionEntity/delete',
  async (payload) => {
    const { deleteRequest } = useRequest();
    return (await deleteRequest(`CollectionEntity/Delete?id=${payload}`));
  }
);

export const addCollectionToUser: AsyncThunk<{
  entityId: number;
  userId: number;
}> = createAsyncThunk(
  'collectionEntity/addCollectonToUser',
  async (payload) => {
    const { post } = useRequest();
    return (await post('Collection/Add', payload))?.data;
  }
);

export const getWatchProviders = createAsyncThunk(
  'collectionEntity/getWatchProviders',
  async () => {
    const { get } = useRequest();
    return (await get('CollectionEntity/GetWatchProviders'))?.data;
  }
);

export const getCollectionEntityById: AsyncThunk<boolean> = createAsyncThunk(
  'collectionEntity/getCollectionEntityById',
  async (payload) => {
    const { get } = useRequest();
    return (await get(`CollectionEntity/Get?id=${payload}`))?.data;
  }
);

export const getCollectionEntityHistory = createAsyncThunk(
  'collectionEntity/collectionEntityHistory',
  async (payload) => {
    const { get } = useRequest();
    return (
      await get(
        `CollectionEntity/GetCollectionEntityHistory?id=${payload}&showUserInfo=true`
      )
    )?.data;
  }
);

export const mergeCollectionEntities = createAsyncThunk(
  'collectionEntity/mergeCollectionEntities',
  async (payload) => {
    const { post } = useRequest();
    console.log(payload);

    return (await post(`CollectionEntity/MergeCollectionEntities`, payload))
      ?.data;
  }
);

// GetCollectionEntityHistory

// Action creators are generated for each case reducer function
export const { setCollectionEntityLoading, setCollectionEntitySearchResult } =
  collectionEntitySlice.actions;

export const getCollectionEntityLoading = ({ collectionEntity }) =>
  collectionEntity.isLoading;
export const getCollectionEntitySearchResult = ({ collectionEntity }) =>
  collectionEntity.searchResult;

export const getLoadings = ({ collectionEntity }) => {
  return {
    collectionEntityLoading: collectionEntity.searchResult,
    collectionEntitySearchResult: collectionEntity.searchResult,
  };
};

export default collectionEntitySlice.reducer;
