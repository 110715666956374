export const setCollectionTypeColor = (collectionType, opacityLevel) => {
  let color: string = '';
  switch (collectionType) {
  case 'Place':
    color = '#108ee9';
    break;

  case 'Movie':
    color = '#219544';
    break;

  case 'Book':
    color = '#842195';
    break;
  }

  if (opacityLevel) { color += opacityLevel; }
  return color;
};
