import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth/authSlice';
import cmsReducer from '../features/cms/cmsSlice';
import collectionAttributeReducer from '../features/collectionAttributes/collectionAttributeSlice';
import collectionEntityReducer from '../features/collectionEntities/collectionEntitySlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    collectionEntity: collectionEntityReducer,
    collectionAttribute: collectionAttributeReducer,
    cms: cmsReducer
  }
});
