// @ts-nocheck
import {
  GlobalOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import { Card, Col, Collapse, DatePicker, Empty, Form, Image, message, PageHeader, Pagination, Row, Select, Skeleton, Spin, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fallBackImage } from '../../../assets/images/fallbackImage';
// import { getUserEntityLoading, setUserEntityLoading } from '../userEntitySlice'
import { searchCollectionEntitySearchResults } from '../../collectionAttributes/collectionAttributeSlice';
import { setCollectionTypeColor } from '../../utils/helper/commonHelper';
import { SelectUser } from '../components/SelectUser';

interface CollectionType {
  Movie: 'Movie',
  Place: 'Place',
  Book: 'Book',
}

/** Common Entity Model for all types */
interface EntityModel {
  authors: string[],
  collectionType: CollectionType,
  coverImage: string,
  address: string,
  createdOnUtc: string,
  description: string,
  directors: string[],
  externalGroupKey: string,
  externalId: string,
  genres: string[],
  id: number,
  placeTypes: string[],
  releaseDate: string,
  runtime: number,
  subTitle: string,
  title: string,
  youtubeId: string,
  city: string,
  country: string,
  countryCode: string,
  latitude: string,
  longitude: string,
  phoneNumber: string,
  webSite: string,
  language: string,
  previewLink: string
}

export const UserSearchQuery: React.FC<any> = () => {
  const { Panel } = Collapse;
  const { Option } = Select;
  const dispatch = useDispatch();
  const isLoading = false; // useSelector(getUserEntityLoading) ?? false
  const [selectedUserEntities, selectedUserEntitiesSet] = useState();
  const [selectedUserId, selectedUserIdSet] = useState(0);
  const [selectedEntityType, selectedEntityTypeSet] = useState<string>('');
  const [totalCount, setTotalCount] = useState([]);
  const [lastSearchQuery, setLastSearchQuery] = useState();
  const [defaultCurrentPage, setDefaultCurrentPage] = useState<number>(1);

  const showTotal = () => totalCount ? `Total ${totalCount} items` : '';
  const TOTAL_ITEM_IN_PAGE = 10;

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  useEffect(() => {
    console.log(selectedUserEntities);
  }, [selectedUserEntities]);

  const onUserSelected = async (values) => {
    if (values)
      selectedUserIdSet(values.value);

    setDefaultCurrentPage(1);

    const request = {
      userId: values.value,
      collectionType: selectedEntityType.value
    };

    if (!values)
      delete request.userId;

    if (!selectedEntityType?.value) { return; }
    performSearch(request);
  };

  const onEntityTypeSelected = async (values) => {
    console.log(values);
    selectedEntityTypeSet(values);
    setDefaultCurrentPage(1);

    const apiRequest = {};

    if (values.value) {
      apiRequest.collectionType = values.value;
    }

    if (selectedUserId)
      apiRequest.userId = selectedUserId;

    performSearch(apiRequest);
  };

  const performSearch = async (apiRequest, skipCount = 0, pageSize = TOTAL_ITEM_IN_PAGE) => {
    setLastSearchQuery(apiRequest);

    if (apiRequest.collectionType === 'All') {
      delete apiRequest.collectionType;
    }

    apiRequest.skip = skipCount;
    apiRequest.take = pageSize;

    try {
      // await dispatch(setUserEntityLoading(true))
      const response: any = (await dispatch(searchCollectionEntitySearchResults({ ...apiRequest, skip: (skipCount * pageSize) < 0 ? 0 : skipCount * pageSize, take: pageSize })))?.payload;

      console.log(response.items);
      selectedUserEntitiesSet(response.items);
      setTotalCount(response.totalCount);
      // await dispatch(setUserEntityLoading(false))
    } catch (e) {
      // await dispatch(setUserEntityLoading(false))
      message.error(e.message);
    }
  };

  const onPaginationChange = (currentPage, pageSize) => {
    console.log(currentPage, pageSize);

    setDefaultCurrentPage(currentPage);
    performSearch(lastSearchQuery, currentPage - 1, pageSize);
  };

  const EntityDetailTable = [
    {
      title: 'Key',
      dataIndex: 'key'
    },

    {
      title: 'Value',
      dataIndex: 'value',
      render: (_: any, record: EntityModel) => record.key === 'id'
        ? <a href={`/collection-entities/detail/${record.value}`} target="_blank" rel="noreferrer">{record.value}</a>
        : <>{record.value}</>
    }
  ];

  const tableColumns = {

    Movie: [
      {
        title: 'Cover Image',
        dataIndex: 'coverImage',
        width: 150,
        render: (_: any, record: EntityModel) => {
          return (<Image
            height={100}
            width={100}
            style={{ objectFit: 'cover' }}
            key={`row_image_${record.id}`}
            src={`${record.coverImage}?width=200`}
            fallback={fallBackImage}
            preview={{
              src: record.coverImage?.length > 0 ? record.coverImage : ''
            }}
          />);
        }
      },
      {
        title: 'Title',
        dataIndex: 'title'
      },
      {
        title: 'Subtitle or Year',
        dataIndex: 'subTitle',
        render: (_: any, record: EntityModel) => record.youtubeId && <a href={`https://www.youtube.com/watch?v=${record.youtubeId}`} target="_blank" rel="noreferrer">{record.subTitle} <YoutubeOutlined /></a>
      },
      {
        title: 'Description',
        dataIndex: 'description',
        render: (_, record: EntityModel) => (<p> {record.description?.substr(0, 300) + (record.description?.length > 300 ? '...' : '')} </p>),
        width: 450
      },

      {
        title: 'genres',
        dataIndex: 'genres',
        render: (_, record: EntityModel) => <p>{record.genres?.join(', ')}</p>
      }
    ],

    Place: [{
      title: 'Cover Image',
      dataIndex: 'coverImage',
      width: 150,
      render: (_: any, record) => {
        return (<Image
          height={100}
          width={100}
          style={{ objectFit: 'cover' }}
          key={`row_image_${record.id}`}
          src={`${record.coverImage}?width=200`}
          preview={{
            src: record.coverImage?.length > 0 ? record.coverImage : ''
          }}
        />);
      }
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title'

    },
    {
      title: 'subTitle',
      dataIndex: 'subTitle'
    },

    {
      title: 'address',
      dataIndex: 'address',
      render: (_: any, record: EntityModel) => <a href={`https://www.google.com/maps/@${record.latitude},${record.longitude},20z `} target="_blank" rel="noreferrer">{record.address} <GlobalOutlined /></a>

    },
    {
      title: 'genres',
      dataIndex: 'genres',
      key: 'genres'

    },
    {
      title: 'website',
      dataIndex: 'website',
      render: (_: any, record: EntityModel) => record.website ? <a href={`${record.webSite}`} target="_blank" rel="noreferrer">{record.webSite}</a> : ''

    }
    ],

    Book: [{
      title: 'Cover Image',
      dataIndex: 'coverImage',
      width: 150,
      render: (_: any, record) => {
        return (<Image
          height={100}
          width={100}
          style={{ objectFit: 'cover' }}
          key={`row_image_${record.id}`}
          src={`${record.coverImage}?width=200`}
          preview={{
            src: record.coverImage?.length > 0 ? record.coverImage : ''
          }}
        />);
      }
    },
    {
      title: 'title',
      dataIndex: 'title'
    },

    {
      title: 'subTitle',
      dataIndex: 'subTitle'
    },

    {
      title: 'language',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'description',
      dataIndex: 'description',
      width: 400,
      render: (_: any, record: EntityModel) => <p>{record.description ? (record.description?.substr(0, 300) + (record.description?.length > 300 ? '...' : '')) : ''}</p>
    },
    {
      title: 'previewLink',
      dataIndex: 'previewLink',
      key: 'previewLink',
      render: (_: any, record: EntityModel) => record.previewLink ? <a href={`${record.previewLink}`} target="_blank" rel="noreferrer">{record.previewLink}</a> : ''

    }
    ]

  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Search User and Collections"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
          <Row style={{ marginBottom: 24 }}>
            <Spin spinning={isLoading}>
              <Form
                layout={'inline'}>
                <Row>
                  <Form.Item
                    label="User Name"
                    name="userName"
                  >
                    <SelectUser
                      labelInValue
                      allowClear
                      showSearch
                      onChange={onUserSelected}
                      value={{}}
                      filterOption={false} />
                  </Form.Item>

                  <Form.Item
                    label="Entity Type"
                    name="entityType"
                  >
                    <Select
                      labelInValue
                      showSearch
                      allowClear
                      onChange={onEntityTypeSelected}
                      style={{ width: 240 }}
                      filterOption={false}>
                      <Option value="All">All</Option>
                      <Option value="Book">Book</Option>
                      <Option value="Place">Place</Option>
                      <Option value="Movie">Movie</Option>
                    </Select>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    style={{ display: 'none' }}
                    label="Searching Date"
                    name="searchingDate"
                  >
                    <DatePicker />
                  </Form.Item>
                </Row>
              </Form>
            </Spin>
          </Row>
        </div>

        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
          <Spin spinning={isLoading}>
            <Skeleton loading={isLoading} active >
              {
                selectedUserEntities?.length
                  ? <Collapse defaultActiveKey={0}>
                    {selectedUserEntities.map((item, index) => {
                      const time = dayjs(item.createdOnUtc).utc(item.createdOnUtc).local().format('DD MMMM YYYY, HH:mm:ss, dd');
                      const timeTag = <Tag key={`tag_id_${item.createdOnUtc}`} color="#108ee9" title={item.createdOnUtc} >{time}</Tag>;
                      const userInformation = <Tag key={`tag_id_userw_${item.createdOnUtc}`} style={{ textAlign: 'center' }} color="green" title={item.createdOnUtc}>{`${item.user.userName + ' ' + item.user.surname} #${item.user.id}`}</Tag>;
                      const collectionType = <Tag key={`coll_tag_id_${index}`} color={setCollectionTypeColor(item.collectionType)} title={item.collectionType}>{item.collectionType}</Tag>;
                      return <Panel header={<>{collectionType} {item.query} </>}
                        extra={[userInformation, timeTag]}
                        key={index} >
                        <Card>
                          <Table
                            key={index}
                            rowKey={record => record.id}
                            size="small"
                            expandable={{
                              expandedRowRender: record => {
                                const columnsNames = Object.keys(record);
                                const dataSource = [];
                                columnsNames.forEach((_, index) => dataSource.push({
                                  key: Object.keys(record)[index],
                                  value: Object.values(record)[index]
                                }));
                                return <Table
                                  size="small"
                                  key={'subTable_' + index}
                                  rowKey={record => 'subTable_' + record.id}
                                  pagination={false} dataSource={dataSource} columns={EntityDetailTable} />;
                              },
                              rowExpandable: record => record.id !== 'Not Expandable'
                            }}
                            columns={tableColumns[item.collectionType]} dataSource={item.results} pagination={true} />
                        </Card>
                      </Panel>;
                    }
                    )}
                  </Collapse>
                  : <Card><Empty /></Card>
              }
            </Skeleton>
            <Pagination
              size="small"
              total={totalCount}
              current={defaultCurrentPage}
              showTotal={showTotal}
              showQuickJumper
              onChange={(currentPage, pageSize) => onPaginationChange(currentPage, pageSize)}
            />
          </Spin>
        </div>
      </Content>
    </div>
  );
};
