enum CollectionAttributeTypes {
  Country = 'Country',
  City = 'City',
  County = 'County',
  PlaceTypes = 'PlaceTypes',
  Genre = 'Genre',
  Director = 'Director',
  Author = 'Author',
}

export default CollectionAttributeTypes;
