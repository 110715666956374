import { Button, Card, Col, Form, Input, notification, PageHeader, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRequest } from '../../utils/hooks/requestHook';
import { getAuthIsLoading } from '../authSlice';

const EditorCreate: React.FC = () => {
  const isLoading = useSelector(getAuthIsLoading) || false;
  const history = useHistory();
  const [form] = Form.useForm();

  const MIN_PASSWORD_LENGTH = 6;
  const MAX_PASSWORD_LENGTH = 9;
  const { post } = useRequest();

  const onFormFinish = async (values) => {
    const request: any = values;
    request.Roles = [values.Roles];

    await post('Auth/Signup', request)
      .then(result => {
        if (result.status === 200) {
          notification.open({
            message: 'Success',
            description: 'New user has been created'
          });
        } else {
          notification.error({
            message: 'Not Completed',
            description: 'Something happened and seems that operation is failed'
          });
        }
        console.log(result);
      })
      .catch(error => {
        notification.error({
          message: 'Service Error',
          description: (error.message || 'Something unexpected happen. Api returned error. See console for details.')
        });
        console.error(error);
      });
  };

  const onFormFinishFailed = async () => {
    notification.error({ message: 'Please provide correct informations' });
  };

  return (<div>
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Editor Preferences"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <Card bordered>
          <Spin spinning={isLoading}>
            <Row>
              <Col span={12}>
                <Form
                  name="preferencesOfCurrentEditor"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  form={form}
                  onFinish={onFormFinish}
                  onFinishFailed={onFormFinishFailed}
                >

                  <Form.Item
                    label="Username"
                    name="Username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="Email"
                    label="E-mail"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Name"
                    name="Name"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Surname"
                    name="Surname"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="Password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your new password!'
                      },
                      {
                        max: MAX_PASSWORD_LENGTH,
                        message: `Value should be less than ${MAX_PASSWORD_LENGTH} character`
                      },
                      {
                        min: MIN_PASSWORD_LENGTH,
                        message: `Value should be less than ${MIN_PASSWORD_LENGTH} character`
                      }
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="NewPassword"
                    label="Confirm Password"
                    dependencies={['Password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your new password!'
                      },
                      {
                        max: MAX_PASSWORD_LENGTH,
                        message: `Value should be less than ${MAX_PASSWORD_LENGTH} character`
                      },
                      {
                        min: MIN_PASSWORD_LENGTH,
                        message: `Value should be less than ${MIN_PASSWORD_LENGTH} character`
                      },
                      ({ getFieldValue }) => ({
                        validator (_, value) {
                          if (!value || getFieldValue('Password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        }
                      })
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="Roles"
                    label="Roles"
                    rules={[{ required: true, message: 'Please select gender!' }]}>
                    <Select>
                      <Select.Option value="Admin">Admin</Select.Option>
                      {/* <Select.Option value="Developer">Developer</Select.Option>
                      <Select.Option value="Editor">Editor</Select.Option> */}
                    </Select>
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                                            Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Spin>
        </Card>
      </Content>
    </div>
  </div>
  );
};

export default EditorCreate;
