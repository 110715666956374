// @ts-nocheck
import {
  Button, Col, PageHeader, Result, Row, Spin} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import {getCollectionEntityById, getCollectionEntityLoading,setCollectionEntityLoading } from '../collectionEntitySlice';
import { AddCollectionToUserModal } from '../components/AddCollectionToUserModal';

export const CollectionEntitySuccessPage = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [collectionEntity, setCollectionEntity] = useState();
  const { collectionEntityId } = useParams();
  const isLoading = useSelector(getCollectionEntityLoading);
  const dispatch = useDispatch();

  const onOk = () => {
    setVisible(false);
    history.push(`/collection-entities/edit/${entityId}`);
  };

  useEffect(() => {
    if (collectionEntityId > 0) {
      getDetails(collectionEntityId);
    } else {
    }
  }, [collectionEntityId]);


  const getDetails = async (collectionEntityId) => {
    if ((collectionEntityId ?? 0) === 0) return;
    console.log(collectionEntityId);

    try {
      await dispatch(setCollectionEntityLoading(true));
      const response = await dispatch(
        getCollectionEntityById(collectionEntityId)
      );

      console.log(114, response.payload);
      setCollectionEntity(response.payload);

      await dispatch(setCollectionEntityLoading(false));
    } catch (e) {
      console.log(e.message);
      await dispatch(setCollectionEntityLoading(false));
    }
  };
  return collectionEntityId > 0
    ? (
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title="Collection Entity Edit Success"
        />
        <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, textAlign: 'center' }}
          >
            <Spin spinning={isLoading}>
              <Row>
                <Col span={20}>

                  <React.Fragment>
                    <Result
                      status="success"
                      title={`Successfully: ${collectionEntity?.title}`}
                      subTitle={`Id: ${collectionEntityId} Not yet listed in search results.`}
                      extra={[
                        <Button type="primary" key="console" onClick={() => history.push('/collection-entities/search')}>
                        Go Search Console
                        </Button>,
                        <Button onClick={() => setVisible(true)} key="add">Add User's Collection</Button>,
                        <Button onClick={() => history.goBack()} key="add">Go Back to Edit This Collection</Button>,
                        <Button href={`/collection-entities/detail/${collectionEntityId}`} key={`${collectionEntityId}_key`} >See Entity Detail</Button>
                      ]}
                    />
                    <AddCollectionToUserModal collectionEntityId={collectionEntityId}
                      visible={visible}
                      onOk={onOk}
                      onCancel={() => setVisible(false)} />
                  </React.Fragment>
                </Col>
              </Row>
            </Spin>
          </div>
        </Content>
      </div>
    ) :
    <Redirect to={`/collection-entities/edit/${collectionEntityId}`}/>;
};
