import React from 'react';

import { useRequest } from '../features/utils/hooks/requestHook';

export const Home: React.FC<any> = () => {
  async function Test() {
    const { post } = useRequest();
    await post('User/SearchUsers', { skip: 0, take: 1, query: 'a' });
  }

  Test();

  return (
    <div></div>);
};
