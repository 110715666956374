// @ts-nocheck
import './index.css';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  notification,
  PageHeader,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fallBackImage } from '../../../assets/images/fallbackImage';
import { setCollectionTypeColor } from '../../utils/helper/commonHelper';
import {
  deleteCollectionEntity,
  getCollectionEntityLoading,
  getCollectionEntitySearchResult,
  searchCollectionEntity,
  setCollectionEntityLoading,
  setCollectionEntitySearchResult,
} from '../collectionEntitySlice';

type Props = {};

interface CollectionTypes {
  Movie: 'Movie';
  Place: 'Place';
  Book: 'Book';
  All: 'All';
}

enum CollectionType {
  Movie = 'Movie',
  Place = 'Place',
  Book = 'Book',
  All = 'All',
}

export const CollectionEntititySearchPage: React.FC<Props> = (_) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const TOTAL_ITEM_IN_PAGE = 10;

  const isLoading = useSelector(getCollectionEntityLoading);
  const searchResult = useSelector(getCollectionEntitySearchResult);

  const [searchKeyword, searchKeywordSet] = useState('');
  const [data, setData] = useState([]);
  const [selectedCollectionType, setSelectedCollectionType] =
    useState<CollectionTypes>(CollectionType.All);
  const [form] = Form.useForm();

  const showTotal = () =>
    data.totalCount ? `Total ${data.totalCount} items` : '';
  const specifyCollectionTypes = () => {
    return {
      movieCount: data.items?.filter((i) => i.collectionType === 'Movie')
        .length,
      placeCount: data.items?.filter((i) => i.collectionType === 'Place')
        .length,
      bookCount: data.items?.filter((i) => i.collectionType === 'Book').length,
    };
  };

  useEffect(() => {
    console.log(searchResult);
    // setData(searchResult)
  }, [searchResult]);

  const [tableColumns] = useState([
    {
      title: 'Id',
      dataIndex: 'entityId',
      render: (_, record) => (
        <Tag
          key={`row_id_${record.id}`}
          color={setCollectionTypeColor(record.collectionType)}
        >
          #{record.entityId}
        </Tag>
      ),
    },
    {
      title: 'Love/Wanna',
      dataIndex: 'love-wannaCount',
      render: (_: any, record) => `${record.loveCount}/${record.wannaCount}`,
    },
    {
      title: 'Cover Image',
      dataIndex: 'coverImage',
      width: 150,
      render: (_: any, record) => {
        return (
          <Image
            height={100}
            width={100}
            style={{ objectFit: 'cover' }}
            key={`row_image_${record.id}`}
            src={`${record.coverImage}?width=200`}
            fallback={fallBackImage}
            preview={{
              src: record.coverImage?.length > 0 ? record.coverImage : '',
            }}
          />
        );
      },
    },
    {
      title: 'Header Metadata',
      dataIndex: 'title',
      render: (_: any, record) => (
        <div key={`row_title${record.id}`}>
          <p>
            <b>Title:</b> {record.title}
          </p>
          <p>
            <b>Sub Title:</b>
            <Tooltip
              title={record.subTitle}
              overlayInnerStyle={{ width: '25em' }}
              color={setCollectionTypeColor(record.collectionType)}
            >
              {record.subTitle?.substr(0, 300) +
                (record.subTitle?.length > 300 ? '...' : '')}{' '}
            </Tooltip>
          </p>
        </div>
      ),
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      width: 250,
      render: (_: any, record) => (
        <div key={`row_operations_${record.id}`}>
          <Button
            key={`edit_${record.entityId}`}
            onClick={() =>
              history.push(`/collection-entities/edit/${record.entityId}`)
            }
            type="primary"
            size="small"
          >
            Edit
          </Button>
          <Button
            key={`delete_${record.entityId}`}
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={() => onDelete(record.entityId)}
            type="primary"
            danger
            size="small"
          >
            Delete
          </Button>
          <Button
            key={`details_${record.entityId}`}
            onClick={() =>
              history.push(`/collection-entities/detail/${record.entityId}`)
            }
            type="primary"
            size="small"
          >
            Details
          </Button>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    console.log(101, selectedCollectionType);
  }, [selectedCollectionType]);

  const { Search } = Input;
  const collectionTypesByCount = specifyCollectionTypes();

  const onSearch = async (
    values,
    skipCount = 0,
    pageSize = TOTAL_ITEM_IN_PAGE
  ) => {
    console.log(values);
    await dispatch(setCollectionEntityLoading(true));
    const request: {
      query: string;
      skip: number;
      take: number;
      collectionType: CollectionTypes;
    } = {
      query: values.query || '',
      skip: skipCount * pageSize < 0 ? 0 : skipCount * pageSize,
      take: +values.take || pageSize,
    };

    if (values.collectionType != 'All') {
      request.collectionType = values.collectionType;
    }

    const response = await dispatch(searchCollectionEntity(request));

    if (response?.payload?.items?.length > 0) {
      setData(response?.payload);
      // save into redux
      await dispatch(setCollectionEntitySearchResult(response?.payload));
      searchKeywordSet(values);
    } else {
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${values.query} `,
        key: values.query,
      });
    }

    await dispatch(setCollectionEntityLoading(false));
  };

  const onDelete = (entityId) => {
    Modal.confirm({
      title: 'Do you want to delete this entity?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        try {
          await dispatch(setCollectionEntityLoading(true));

          dispatch(deleteCollectionEntity(entityId))
            .then((res) => {
              if (res?.status === 200 || res?.payload?.status === 200) {
                setData([]);
                message.success('Success, deleted');
              } else {
                message.error(
                  res?.payload?.data?.title +
                    ' - ApiResponse: ' +
                    res?.payload?.data?.message
                );
              }
            })
            .catch((rejectedValueOrSerializedError) => {
              dispatch(setCollectionEntityLoading(false));
              message.error(rejectedValueOrSerializedError.message);
              // handle error here
            });
        } catch (e) {
          message.error(e.message);
        } finally {
          await dispatch(setCollectionEntityLoading(false));
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onPaginationChange = (currentPage, pageSize) => {
    onSearch(searchKeyword, currentPage - 1, pageSize);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Collection Entities"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span="18">
              <Form
                layout={'inline'}
                form={form}
                size={'small'}
                labelWrap
                labelAlign="left"
                colon={true}
                onFinish={onSearch}
              >
                <Form.Item label="Collection Type" name="collectionType">
                  <Select
                    defaultValue={'All'}
                    placeholder="Select Collection Type"
                  >
                    <Select.Option value="All">All Types</Select.Option>
                    <Select.Option value="Place">Place</Select.Option>
                    <Select.Option value="Movie">Movie</Select.Option>
                    <Select.Option value="Book">Book</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Query" name="query">
                  <Input placeholder="Searching Query" />
                </Form.Item>
                <Form.Item style={{ width: '100px' }} label="take" name="take">
                  <Input
                    type="number"
                    defaultValue={10}
                    min={0}
                    placeholder={10}
                  />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col style={{ textAlign: 'right' }} span={6}>
              {data.totalCount && (
                <span>
                  {showTotal()}{' '}
                  {searchKeyword.query && (
                    <>
                      with the query{' '}
                      <i className={'totalCountBadge'}>{searchKeyword.query}</i>
                    </>
                  )}{' '}
                </span>
              )}

              {data.totalCount ? (
                <span>
                  Movie: {collectionTypesByCount.movieCount}, Book:{' '}
                  {collectionTypesByCount.bookCount}, Place:{' '}
                  {collectionTypesByCount.placeCount} on that page{' '}
                </span>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row></Row>
          <Table
            loading={isLoading}
            columns={tableColumns}
            dataSource={searchResult?.items || data.items}
            pagination={false}
          />
          <Pagination
            size="small"
            total={data.totalCount}
            showTotal={showTotal}
            showQuickJumper
            onChange={(currentPage, pageSize) =>
              onPaginationChange(currentPage, pageSize)
            }
          />
        </div>
      </Content>
    </div>
  );
};
