import { PageHeader } from 'antd';
import React from 'react';

export const AiDashboard: React.FC = (props: any) => {
  const url = 'https://model-lizard-native.ngrok-free.app/';

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="AI Dashboard"
      />
      <div style={{ paddingLeft: 30 }}>
        <span style={{display:'block', fontSize:12}}>Below section is served as IFrame.</span>
        <a href={url} target="_blank" rel="noopener noreferrer">
          Click here to go original site{' '}
        </a>
      </div>
      <iframe
        style={{
          height: '80vh',
          width: '75vw',
          padding: '20px',
        }}
        title="ai"
        key={'ai'}
        width={'100%'}
        height={'100%'}
        src={url}
      />
    </div>
  );
};
