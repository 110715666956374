import './custom.css';

import React from 'react';
import { Route } from 'react-router';

import { AppLayout } from './components/AppLayout';
import { Home } from './components/Home';
import EditorCreate from './features/auth/pages/EditorCreate';
import EditorPreferences from './features/auth/pages/EditorPreferences';
import { LoginPage } from './features/auth/pages/LoginPage';
import { ReportedContentsPage } from './features/cms/pages/ReportedContentsPage';
import { CollectionAttributeSearchPage } from './features/collectionAttributes/pages';
import { AttributeMerge } from './features/collectionAttributes/pages/AttributeMerge';
import { CollectionAttributeDetailPage } from './features/collectionAttributes/pages/CollectionAttributeDetailPage';
import { PopularAttributesPage } from './features/collectionAttributes/pages/PopularAttributesPage';
import { CollectionEntititySearchPage } from './features/collectionEntities/pages';
import { CollectionEntityDetailPage } from './features/collectionEntities/pages/CollectionEntityDetailPage';
import { CollectionEntityEditPage } from './features/collectionEntities/pages/CollectionEntityEditPage';
import { CollectionEntitityMergePage } from './features/collectionEntities/pages/CollectionEntityMerge';
import { CollectionEntitySuccessPage } from './features/collectionEntities/pages/CollectionEntitySuccessPage';
import { AiDashboard } from './features/external/aiDashboard';
import {UpdateTestflightUrl} from './features/testflightUrl/pages/UpdateTestflightUrl'
import { UserSearchQuery } from './features/user/pages';
import { SearchUsers } from './features/user/pages/SearchUsers';
import { authKey } from './features/utils/hooks/requestHook';

export const App = () => {
  const accessTokenLocal = localStorage.getItem(authKey);

  return (
    <Route path="/">
      {(accessTokenLocal?.length ?? 0) > 0 ? (
        <AppLayout>
          <Route path="/dashboard" component={Home} />

          <Route
            path="/collection-entities/search"
            component={CollectionEntititySearchPage}
          />
          <Route
            path="/collection-entities/merge"
            component={CollectionEntitityMergePage}
          />

          <Route
            path="/collection-entities/edit/:collectionEntityId"
            component={CollectionEntityEditPage}
          />
          <Route
            path="/collection-entities/success/:collectionEntityId"
            component={CollectionEntitySuccessPage}
          />
          <Route
            path="/collection-entities/detail/:collectionEntityId"
            component={CollectionEntityDetailPage}
          />
          <Route
            path="/collection-attributes/merge"
            component={AttributeMerge}
          />

          <Route
            path="/collection-attributes/search"
            component={CollectionAttributeSearchPage}
          />
          <Route
            path="/collection-attributes/edit/:collectionAttributeId"
            component={CollectionAttributeDetailPage}
          />
          <Route
            path="/collection-attributes/detail/:collectionAttributeId"
            component={CollectionAttributeDetailPage}
          />
          <Route
            path="/collection-attributes/popular-attributes"
            component={PopularAttributesPage}
          />

          <Route path="/ai" component={AiDashboard} />

          <Route path="/cms/pending-actions" component={ReportedContentsPage} />
          <Route
            path="/cms/moderation-reports"
            component={ReportedContentsPage}
          />

          <Route path="/user/search-user-queries" component={UserSearchQuery} />
          <Route path="/user/search-user" component={SearchUsers} />

          <Route path="/editor/new" component={EditorCreate} />
          <Route path="/editor/preferences" component={EditorPreferences} />

          <Route path="/testflighturl/update" component={UpdateTestflightUrl} />
        </AppLayout>
      ) : (
        <LoginPage />
      )}
    </Route>
  );
};
