// @ts-nocheck
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Image, message, Modal, PageHeader, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fallBackImage } from '../../../assets/images/fallbackImage';
import {
  dismissReportedContent,
  fetchReportedContents,
  getCmsLoading,
  removeReportedContent,
  setCmsLoading
} from '../cmsSlice';

type Props = {};

export const ReportedContentsPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const state = window.top.location.hash.replace('#', '');

  const isLoading = useSelector(getCmsLoading);

  const [tableColumns] = useState([
    {
      title: 'Id',
      width: 30,
      dataIndex: 'entry',
      render: (_: any, record) => <Tag key={`row_id_${record.id}`} color="#108ee9">#{record.entry.id}</Tag>
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: 150,
      render: (_: any, record) => <Tag key={`row_id_${record.id}`} color={getSourceTagColor(record.source)}>{renderSource(record.source)}</Tag>
    },
    {
      title: 'Content Preview',
      dataIndex: 'entry',
      render: (_: any, record) => {
        return (<div>
          {renderPreview(record)}
          {record.source === 'HOOPT' ? <p><strong>Reasons:</strong> {record.reports.map((p, i) => `${getReason(p.reason)}${i === (record.reports.length - 1) ? '' : ', '}`)}</p> : ''}
        </div>);
      }
    },
    {
      title: 'Total Report Count',
      dataIndex: 'reports',
      width: 200,
      render: (_: any, record) => <div style={{ textAlign: 'center' }} key={`row_title${record.id}`}>
        <Tag key={`row_id_${record.id}`} color="red">{record.reports.length}</Tag>
      </div>
    },
    {
      title: 'State',
      dataIndex: 'id',
      width: 150,
      render: (_: any, record) => <Tag key={`row_id_${record.id}`} color={getActionColors(record.state)}>{getActionDisplayName(record.state)}</Tag>
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      width: 200,
      render: (_: any, record) => <div key={`row_operations_${record.id}`}>
        {record.type === 'User' && <Button disabled={record.state !== 'PendingAction'} key={`delete_${record.entityId}`} style={{ marginLeft: 10 }} onClick={() => onRemove(record)} type="primary" danger
          size="small">Suspend</Button>}
        {record.type !== 'User' && <Button disabled={record.state !== 'PendingAction'} key={`delete_${record.entityId}`} style={{ marginLeft: 10 }} onClick={() => onRemove(record)} type="primary" danger
          size="small">Remove</Button>}
        <Button disabled={record.state !== 'PendingAction'} key={`delete_${record.entityId}`} style={{ marginLeft: 10 }} onClick={() => onDismiss(record)} type="default"
          size="small">Dismiss</Button>
      </div>
    }
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    onSearch();
  }, []);

  const onSearch = async () => {
    await dispatch(setCmsLoading(true));
    const response: any = await dispatch(fetchReportedContents({ state: (state === 'pending' ? 'PendingAction' : undefined), skip: 0, take: state === 'pending' ? 9999 : 100 }));
    setData(response?.payload?.items);
    await dispatch(setCmsLoading(false));
  };

  const onDismiss = (item) => {
    Modal.confirm({
      title: 'Do you want to dismiss this entity?',
      icon: <ExclamationCircleOutlined />,
      async onOk () {
        try {
          await dispatch(setCmsLoading(true));
          await dispatch(dismissReportedContent({ type: item.type, userId: item.userId, entryId: item.entry.id }));
          onSearch();
          message.success('Success');
        } catch (e) {
          message.error(e.message);
        } finally {
          dispatch(setCmsLoading(false));
        }
      },
      onCancel () {
        console.log('Cancel');
      }
    });
  };

  const onRemove = (item) => {
    Modal.confirm({
      title: 'Do you want to remove this entity?',
      icon: <ExclamationCircleOutlined />,
      async onOk () {
        try {
          await dispatch(setCmsLoading(true));
          await dispatch(removeReportedContent({ type: item.type, id: item.entry.id }));
          await onSearch();
          message.success('Success');
        } catch (e) {
          message.error(e.message);
        } finally {
          dispatch(setCmsLoading(false));
        }
      },
      onCancel () {
        console.log('Cancel');
      }
    });
  };

  const renderSource = (source) => {
    switch (source) {
    case 'HOOPT':
      return 'HOOPT Users';
    case 'RealtimeAI':
      return 'Realtime AI';
    }
  };

  const getSourceTagColor = (source) => {
    switch (source) {
    case 'HOOPT':
      return 'green';
    case 'RealtimeAI':
      return 'red';
    }
  };

  const getReason = (source) => {
    switch (source) {
    case 'InappropriateContent':
      return 'Inappropriate content';
    case 'DangerousContent':
      return 'Dangerous or violent';
    case 'FalseInformation':
      return 'False information';
    case 'Spam':
      return 'Spam';
    case 'FakeAccount':
      return 'It’s a fake account';
    case 'Other':
      return 'Other';
    }
  };

  const getActionDisplayName = (source) => {
    switch (source) {
    case 'PendingAction':
      return 'Pending Action';
    case 'AutoRemoved':
      return 'Auto-Removed';
    case 'Removed':
      return 'Removed';
    case 'CaseDismissed':
      return 'Case-Dismissed';
    }
  };

  const getActionColors = (source) => {
    switch (source) {
    case 'PendingAction':
      return 'yellow';
    case 'AutoRemoved':
      return 'red';
    case 'Removed':
      return 'red';
    case 'CaseDismissed':
      return 'blue';
    }
  };

  const renderPreview = (item) => {
    switch (item.type) {
    case 'Comment':
      return (
        <div>
          <p><strong>Type:</strong> Comment</p>
          <p>{item.entry.text}</p>
        </div>
      );
    case 'UserMedia':
      return (<div>
        <p><strong>Type:</strong> User Media</p>
        {item.entry.mediaFullLink && <p><Image
          height={100}
          key={`row_image_${item.entry.id}`}
          src={item.entry.mediaFullLink+ '?width=200'}
          fallback={fallBackImage}
          preview={{
            src: item.entry.mediaFullLink ?? ''
          }}
        /></p>}
      </div>);
    case 'AutomatedAIMedia':
      return (<div>
        <p><strong>Type:</strong> User Media</p>
        <p><Image
          height={100}
          key={`row_image_${item.entry.id}`}
          src={item.entry.mediaFullLink+ '?width=200'}
          fallback={fallBackImage}
          preview={{
            src: item.entry.mediaFullLink ?? ''
          }}
        /></p>
      </div>);
    case 'Quote':
      return (
        <div>
          <p><strong>Type:</strong> Quote</p>
          <p>{item.entry.text}</p>
        </div>
      );
    case 'User':
      return (<div>
        <p><strong>Type:</strong> User</p>
        <p><strong>Full Name:</strong> {item.entry.fullName}</p>
        {item.entry.profilePicture && <p><Image
          height={100}
          style={{ marginLeft: 10 }}
          key={`row_image_${item.entry.id}`}
          src={item.entry.profilePicture}
          fallback={fallBackImage}
          preview={{
            src: item.entry.profilePicture ?? ''
          }}
        /></p>}
      </div>);
    case 'CollectionEntityMedia':
      return (<div>
        <p><strong>Type:</strong> Collection Entity Media</p>
        <p><strong>Collection Type:</strong> {item.entry.collectionType}</p>
        <p><Image
          height={100}
          style={{ marginLeft: 10 }}
          key={`row_image_${item.entry.id}`}
          src={item.entry.mediaFullLink+ '?width=200'}
          fallback={fallBackImage}
          preview={{
            src: item.entry.mediaFullLink ?? ''
          }}
        /></p>
      </div>);
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title={state === 'pending' ? 'Pending Action' : 'Moderated Content'}
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
          <Table loading={isLoading} columns={tableColumns} dataSource={data} pagination={false} />
        </div>
      </Content>
    </div>
  );
};
