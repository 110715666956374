// @ts-nocheck
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch } from 'react-redux';

import { numFormatter } from '../../utils/helper/numberHelpber';
import { searchCollectionAttribute } from '../collectionAttributeSlice';

export const SelectCollectionAttribute = (props: any) => {
  const {
    collectionType,
    collectionAttributeType,
    sortingType,
    skip,
    take,
    debounceTimeout,
    value,
    onChange,
    onSelect,
    initialOptions,
    mode,
  }: any = props;

  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState(
    initialOptions?.map((p) => ({ label: p.value, value: p.value })) ?? []
  );
  const fetchRef = React.useRef(0);
  const dispatch = useDispatch();

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      if ((value?.length ?? 0) === 0) {
        setOptions([{ label: 'No Data', value: 'No Data' }]);
      } else if ((value?.length ?? 0) > 0 && (value?.length ?? 0) < 3) {
        setOptions([
          {
            label: 'Type at least 3 letters',
            value: 'Type at least 3 letters',
            disabled: true,
          },
        ]);
        return;
      }

      fetchRef.current += 1;
      setOptions([]);
      setFetching(true);

      const response = (
        await dispatch(
          searchCollectionAttribute({
            collectionType: collectionType ?? 'Place',
            collectionAttributeType: collectionAttributeType ?? 'City',
            sortingType: sortingType ?? 'CollectionEntityCountDesc',
            skip: skip ?? 0,
            take: take ?? 20,
            query: value,
          })
        )
      )?.payload;

      const result = response?.items?.length
        ? response?.items?.map((p) => ({
          label: `${p.value} - ${numFormatter(p.collectionEntityCount)}`,
          value: p.value,
        }))
        : [{ label: 'No Data', value: 'No Data', disabled: true }];

      setOptions(result);
    };

    return debounce(loadOptions, debounceTimeout ?? 500);
  }, [debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      value={value}
      showSearch
      mode={mode}
      autoClearSearchValue
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      onSelect={onSelect}
      onChange={onChange}
      options={options}
    />
  );
};
