import { Button, Form, Input, message, notification,PageHeader, Row, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect,useState } from 'react';

import { GetTestflightUrl, UpdateTestflightUrlReq } from '../../../services/api';

export const UpdateTestflightUrl: React.FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [testflightUrl, setTestflightUrl] = useState<string>('');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTestflightUrl();
  }, []);

  const fetchTestflightUrl = async () => {
    setLoading(true);
    try {
      GetTestflightUrl()
        .then((result) => {
          setTestflightUrl(result.data);
          form.setFieldsValue({ testflightUrl: result.data });
          setLoading(false);
        })
        .catch((error) => {
          notification.error({
            message: 'Service Error',
            description:
              error.message ||
              'Something unexpected happen. Api returned error. See console for details.',
          });
          console.error(error);
          setLoading(false);
        });
    } catch (e) {
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (!isValidUrl(values.testflightUrl)) {
        notification.error({
          message: 'Error',
          description:
            'Value is not valid URL.',
        });
        throw new Error('Invalid URL');
      }
      UpdateTestflightUrlReq(values.testflightUrl)
        .then((result) => {
          notification.success({
            message: 'Success',
            description: 'Testflight url updated',
          });
        })
        .catch((error) => {
          notification.error({
            message: 'Service Error',
            description:
              error.message ||
              'Something unexpected happen. Api returned error. See console for details.',
          });
          console.error(error);
        });
    } catch (e) {
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Testflight Url"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <div style={{ width: '100%' }}>
              <Spin spinning={loading}>
                <Form
                  form={form}
                  initialValues={{testflightUrl: testflightUrl}}
                  onFinish={handleSave}
                >
                  <Form.Item
                    label="Testflight Url"
                    name="testflightUrl"
                    rules={[{ required: true, message: 'Please input Testflight Url!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" style={{ marginTop: 16 }}>
                    Submit
                  </Button>
                </Form>
              </Spin>
            </div>
          </Row>
        </div>
      </Content>
    </div>
  );
};
