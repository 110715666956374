// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const userEntitySlice = createSlice({
  name: 'userEntity',
  initialState: {
    isLoading: false
  },
  reducers: {
    setUserEntityLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    }
  }
});

export const searchUsers = createAsyncThunk(
  'User/SearchUsers',
  async (payload) => {
    const { post } = useRequest();
    return (await post('User/SearchUsers', payload))?.data;
  }
);

// Action creators are generated for each case reducer function
export const { setUserEntityLoading } = userEntitySlice.actions;

export const getUserEntityLoading = ({ userEntity }) => userEntity.isLoading;
export default userEntitySlice.reducer;
