// @ts-nocheck
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, PageHeader, Row, Select, Spin, Tag, Transfer } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { numFormatter } from '../../utils/helper/numberHelpber';
import {
  getCollectionAttributeLoading,
  getPopularAttributes,
  setCollectionAttributeLoading, updatePopularAttributes
} from '../collectionAttributeSlice';

type Props = {};

export const PopularAttributesPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();
  const [allAttributes, setAllAttributes] = useState([]);
  const [popularAttributes, setPopularAttributes] = useState([]);

  const isLoading = useSelector(getCollectionAttributeLoading);

  const onFieldsChange = (changedFields) => {
    // @ts-ignore
    setFormData({ ...formData, changedFields });
  };

  const onSave = async () => {
    const collectionType = form.getFieldValue('collectionType');
    if (collectionType && popularAttributes?.length >= 10) {
      Modal.confirm({
        title: 'Do you want to save the popular attributes?',
        icon: <ExclamationCircleOutlined />,
        async onOk () {
          try {
            await dispatch(setCollectionAttributeLoading(true));
            await dispatch(updatePopularAttributes({
              collectionType,
              selectedCollectionAttributeIds: popularAttributes
            }));
            await dispatch(setCollectionAttributeLoading(false));
            message.success('Popular attributes updated. Please kill app and open the HOOPT app');
          } catch (e) {
            await dispatch(setCollectionAttributeLoading(false));
            message.error(e.message);
          }
        },
        onCancel () {
          console.log('Cancel');
        }
      });
    }
  };

  const onFinish = async (values) => {
    try {
      await dispatch(setCollectionAttributeLoading(true));
      const response: any = (await dispatch(getPopularAttributes(values)))?.payload;
      setAllAttributes(response.attributes?.map(p => {
        return {
          ...p,
          key: p.id.toString()
        };
      }));

      const popularAttributeValues = response.selectedPopularAttributeIds?.map(p => p.toString());
      setPopularAttributes(popularAttributeValues ?? []);
      await dispatch(setCollectionAttributeLoading(false));
    } catch (e) {
      await dispatch(setCollectionAttributeLoading(false));
      message.error(e.message);
    }
  };

  const handleChange = (targetKeys, direction, moveKeys) => {
    if (direction === 'right') {
      setPopularAttributes([...popularAttributes, ...moveKeys]);
    } else {
      setPopularAttributes(targetKeys);
    }
  };

  const up = (item) => {
    const index = popularAttributes?.findIndex(p => p === item.id.toString());
    if (index === 0) return;

    const finalAttributes = [...popularAttributes];
    finalAttributes.splice(index, 1);
    finalAttributes.splice(index - 1 === 0 ? 0 : index - 1, 0, item.key);
    setPopularAttributes(finalAttributes);
  };

  const down = (item) => {
    const index = popularAttributes?.findIndex(p => p === item.id.toString());
    const finalAttributes = [...popularAttributes];
    finalAttributes.splice(index, 1);
    finalAttributes.splice(index + 1, 0, item.key);
    setPopularAttributes(finalAttributes);
  };

  return (
    <Spin spinning={isLoading}>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Collection Attributes"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
          <Row style={{ marginBottom: 24 }}>
            <Form
              layout="inline"
              form={form}
              style={{ width: '100%' }}
              initialValues={formData}
              onValuesChange={onFieldsChange}
              onFinish={onFinish}
            >
              <Form.Item name="collectionType"
                label="Collection Type"
                style={{ width: 250 }}
                rules={[{
                  required: true, message: 'Please input entity collection type'
                }]}>
                <Select onChange={(value) => {
                  // @ts-ignore
                  setFormData({ ...formData, collectionType: value });
                  setPopularAttributes([]);
                  setAllAttributes([]);
                }}>
                  <Select.Option value="Place">Place</Select.Option>
                  <Select.Option value="Movie">Movie</Select.Option>
                  <Select.Option value="Book">Book</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={isLoading} type="primary">Search Attributes</Button>
                <Button htmlType="button" onClick={onSave} loading={isLoading} type="danger" disabled={!form.getFieldValue('collectionType') || (popularAttributes?.length ?? 0) < 10}
                  style={{marginLeft: 10}}>Save Changes</Button>
              </Form.Item>
            </Form>
          </Row>
          <Transfer
            dataSource={allAttributes}
            showSearch
            listStyle={{
              width: '100%',
              height: 500
            }}
            operations={['to right', 'to left']}
            titles={['Available Popular Attributes', 'Final Popular Attributes']}
            targetKeys={popularAttributes}
            oneWay
            onChange={handleChange}
            filterOption={(inputValue, option) =>
              option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
            }
            render={(item) => {
              const index = popularAttributes.indexOf(item.key);
              return (<div style={{ textAlign: 'left' }}>
                <div style={{ float: 'left', width: '80%' }}>
                  {item.value}
                  <Tag style={{ marginLeft: 10 }} color="red">Love: {numFormatter(item.loveCount)}</Tag>
                  <Tag color="green">Wanna: {numFormatter(item.wannaCount)}</Tag>
                  <Tag color="cyan">Entity: {numFormatter(item.collectionEntityCount)}</Tag>
                </div>
                {index > -1 && <div style={{ float: 'left', width: '20%' }}>
                  <Button disabled={index === 0} onClick={() => up(item)} size="small" type="dashed">+</Button>
                  <Button disabled={index === popularAttributes.length - 1} onClick={() => down(item)} size="small" type="dashed">-</Button>
                </div>}
              </div>);
            }}
          />
        </div>
      </Content>
    </Spin>
  );
};
