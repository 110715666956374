// @ts-nocheck
import { Form, InputNumber, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { addCollectionToUser, setCollectionEntityLoading } from '../collectionEntitySlice';

export const AddCollectionToUserModal: React.FC = ({ visible, onOk, onCancel, collectionEntityId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onOkClick = () => {
    form.submit();
  };
  const onCancelClick = () => {
    onCancel();
  };

  const onFinish = async (values: any) => {
    await dispatch(setCollectionEntityLoading(true));
    values.collectionEntityId = collectionEntityId;
    await dispatch(addCollectionToUser(values));
    await dispatch(setCollectionEntityLoading(false));
    onOk();
  };

  return (
    <Modal
      title="Add Collection to User"
      centered
      visible={visible}
      onOk={onOkClick}
      onCancel={onCancelClick}
    >
      <Form
        name="addCollectionToUserForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="User Id"
          name="userId"
          rules={[{ required: true, message: 'Please input target user id' }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};
