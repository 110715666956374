// @ts-nocheck
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { BookOutlined, CommentOutlined, FileImageOutlined, HeartOutlined } from '@ant-design/icons';
import {
  Card, Checkbox, Col, Image, PageHeader, Radio, Timeline, Tooltip
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getCollectionEntityHistory } from '../collectionEntitySlice';

type Props = {};

enum ItemHistoryType {
  Loved = 'Love',
  Wanna = 'Wanna',
  Commented = 'Commented',
  SearchedFirstTime = 'SearchedFirstTime',
  AddedPhoto = 'AddedPhoto'
}
interface formData {
  id: number;
  coverImage: string;
  images: any;
  county: any;
  country: any;
  genres: any;
}

interface timelineData {
  createdOnUtc: string,
  title: string,
  loveCount: number,
  wannaCount: number,
  feedCount: number,
  actionType: ItemHistoryType,
  collectionType: any,
  isInitialSearch: boolean,
  mediaUrl: string,
  totalCollectionCount: number,
  userId: number
}

export const CollectionEntityDetailPage: React.FC<Props> = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collectionEntityId } = useParams();
  const [value, setValue] = useState(1);
  const [imagePreviews, setImagePreviews] = useState(true);
  const [filterKeys, setFilterKeys] = useState(['Love', 'Wanna', 'Commented', 'AddedPhoto', 'SearchedFirstTime']);
  const [timeLineData, setTimelineData] = useState([]);
  const [baseData, setBaseData] = useState([]);

  useEffect(() => {
    getDetails(collectionEntityId);
  }, [collectionEntityId]);

  const selectIcon = (item: timelineData) => {
    switch (item.actionType) {
    case ItemHistoryType.Loved:
      return {
        color: 'red',
        icon: <HeartOutlined className="timeline-heart-icon" />,
        actionType: ItemHistoryType.Loved
      };
    case ItemHistoryType.Wanna:
      return {
        color: 'gray',
        icon: <BookOutlined className="timeline-book-icon" />,
        actionType: ItemHistoryType.Wanna
      };

    default:
      if (item.isInitialSearch) {
        return {
          color: 'green',
          icon: undefined,
          actionType: ItemHistoryType.SearchedFirstTime

        };
      } else if (item.commentId > 0) {
        return {
          color: 'blue',
          icon: <CommentOutlined className="timeline-comment-icon" />,
          actionType: ItemHistoryType.Commented
        };
      } else if (item.title?.includes('Added photo')) {
        return {
          color: 'brown',
          icon: <FileImageOutlined className="timeline-photo-icon" />,
          actionType: ItemHistoryType.AddedPhoto
        };
      } else {
        return {
          color: 'blue',
          icon: undefined,
          actionType: undefined
        };
      }
    }
  };

  const getDetails = async (collectionEntityId) => {
    const response = await dispatch(getCollectionEntityHistory(collectionEntityId));
    const _temp = response.payload.map(i => {
      return {
        ...i,
        actionType: selectIcon(i).actionType
      };
    }).sort((a, b) => b.isInitialSearch - a.isInitialSearch);

    setTimelineData(_temp);
    setBaseData(_temp);
  };

  const filterTimelineData = (e) => {
    setFilterKeys(e);
  };

  useEffect(() => {
    const _tempFilteredData = baseData && baseData?.filter(i => filterKeys?.includes(i.actionType));
    setTimelineData(_tempFilteredData);
  }, [filterKeys]);

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const manipulateData: timelineData[] = (filterType, timelineData): { filterType: 'day' | 'week' | 'month' | 'quarterly' | 'semiannually', timelineData: timelineData } => {
    setValue(filterType.target.value);

    let groupingFormat = 'DD MMMM YYYY';
    switch (filterType) {
    case 'day':
      groupingFormat = 'DD MMMM YYYY';
      break;
    case 'week':
      groupingFormat = 'YYYY.WW';
      break;
    case 'month':
      groupingFormat = 'YYYY.MM';
      break;

    case 'quarterly':
      groupingFormat = 'YYYY.MM';
      break;

    case 'semiannually':
      groupingFormat = 'YYYY.MM';
      break;
    }

    // timelineData.forEach(i => i.time = moment(i.createdOnUtc).format(groupingFormat));
    // console.log(timelineData[0]);
    // const groupedData = groupBy(timelineData, 'time')
    // const _temp = Object.keys(groupedData).map(i => groupedData[i].map(j => j.title))
    // console.log(_temp)

    // setTimelineData(timelineData);
  };

  const toggleImagePreviews = (e: CheckboxChangeEvent) => { setImagePreviews(e.target.checked); filterTimelineData(); };
  const filterOptions = ['Love', 'Wanna', 'Commented', 'AddedPhoto', 'SearchedFirstTime'];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Collection Entity"
      />
      <Content>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'left' }}
        >
          <Card >
            <Checkbox.Group options={filterOptions} defaultValue={filterOptions} onChange={filterTimelineData} />
            <Radio.Group style={{ display: 'none' }} onChange={(radioEvent) => manipulateData(radioEvent, timeLineData)} value={value} optionType="button"
              buttonStyle="solid">
              <Radio value={'day'}>day</Radio>
              <Radio value={'week'}>week</Radio>
              <Radio value={'month'}>month</Radio>
            </Radio.Group>
          </Card>

          <Card>

            <Col xs={32} md={32} lg={16} xl={16} xxl={12} >
              <Card style={{ overflow: 'scroll', overflowX: 'scroll', overflowY: 'scroll', height: '60vh' }} >
                <Timeline mode={'left'}>
                  {
                    timeLineData.map(i => {
                      // set tooltip string
                      const tooltipTime = moment(i.createdOnUtc).format('dddd | ll | HH:mm');
                      const tooltipWarning = i.isInitialSearch ? 'Time value might be incorrect for old datas!' : undefined;
                      return (<Timeline.Item
                        key={i.createdOnUtc + '_' + Math.random() * 1000}
                        dot={
                          selectIcon(i)?.icon}
                        color={selectIcon(i)?.color}
                        label={
                          <Tooltip placement="top" title={tooltipWarning || tooltipTime}
                          >
                            {moment(i.createdOnUtc).format('DD MMMM YYYY  | HH:mm ')
                            }
                            {tooltipWarning ? '*' : ''}
                          </Tooltip>

                        }>
                        <Tooltip placement="top" title={i.actionType == ItemHistoryType.AddedPhoto && imagePreviews ? <Image src={i.mediaUrl + '?width=200'} preview={{ src: i.mediaUrl }} /> : <>{i.title} at {moment(i.createdOnUtc).format('dddd | ll | HH:mm')}</>}>
                          {`${i.title} #${i.totalCollectionCount} ❤️${i.loveCount}  🔖${i.wannaCount}`}
                        </Tooltip>
                      </Timeline.Item>);
                    })
                  }
                </Timeline>              </Card>
            </Col>

            <div>
              Loved:
              Shown {timeLineData.filter(i => i.actionType == ItemHistoryType.Loved)?.length} of {baseData.filter(i => i.actionType == ItemHistoryType.Loved)?.length}
            </div>
            <div>
              Wanna:
              Shown  {timeLineData.filter(i => i.actionType == ItemHistoryType.Wanna)?.length} of {baseData.filter(i => i.actionType == ItemHistoryType.Wanna)?.length}
            </div>
            <div>
              Commented:
              Shown  {timeLineData.filter(i => i.actionType == ItemHistoryType.Commented)?.length} of {baseData.filter(i => i.actionType == ItemHistoryType.Commented)?.length}
            </div>
            <div>
              Added Photo:
              Shown  {timeLineData.filter(i => i.actionType == ItemHistoryType.AddedPhoto)?.length} of {baseData.filter(i => i.actionType == ItemHistoryType.AddedPhoto)?.length}
            </div>
          </Card>
        </div>
      </Content >
    </div >
  );
};
