import { useRequest } from '../features/utils/hooks/requestHook';

export const ResetUserPassword = async (userId: number) => {
  const { post } = useRequest();
  return (await post('user/SendResetPasswordEmail', { userId }));
};
export const ChangeUserPassword = async (userId: number, password:string) => {
  const { post } = useRequest();
  return (await post('user/ChangeUserPassword', { userId, password }));
};

export const GetTestflightUrl = async () => {
  const { get } = useRequest();
  return (await get('setting/gettestflighturl'));
};

export const UpdateTestflightUrlReq = async (testflightUrl:string) => {
  const { post } = useRequest();
  return (await post('setting/updatetestflighturl', { testflightUrl }));
};
