// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const collectionAttributeSlice = createSlice({
  name: 'collectionAttribute',
  initialState: {
    isLoading: false
  },
  reducers: {
    setCollectionAttributeLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    }
  }
});

export const searchCollectionAttribute = createAsyncThunk('collectionAttribute/search', async (payload) => {
  const { post } = useRequest();
  return (await post('CollectionEntity/SearchAttributes', payload))?.data;
});

export const deleteCollectionAttribute = createAsyncThunk('collectionAttribute/delete', async (payload) => {
  const { deleteRequest } = useRequest();
  return (await deleteRequest(`CollectionEntity/DeleteAttribute?id=${payload}`))?.data;
});

export const getCollectionAttributeById = createAsyncThunk('collectionEntity/getCollectionAttributeById', async (payload) => {
  const { get } = useRequest();
  return (await get(`CollectionEntity/GetAttributeById?id=${payload}`))?.data;
});

export const updateCollectionAttribute = createAsyncThunk('collectionEntity/addCollectonToUser', async (payload) => {
  const { post } = useRequest();
  return (await post('CollectionEntity/UpdateAttribute', payload))?.data;
});

export const getPopularAttributes = createAsyncThunk('collectionEntity/getPopularAttributes', async (payload) => {
  const { post } = useRequest();
  return (await post('CollectionEntity/GetPopularAttributes', payload))?.data;
});

export const updatePopularAttributes = createAsyncThunk('collectionEntity/updatePopularAttributes', async (payload) => {
  const { put } = useRequest();
  return (await put('CollectionEntity/UpdatePopularAttributes', payload))?.data;
});

export const searchCollectionEntitySearchResults = createAsyncThunk('collectionEntity/updatePopSearchCollectionEntitySearchResultsularAttributes', async (payload) => {
  const { post } = useRequest();
  return (await post('CollectionEntity/SearchCollectionEntitySearchResults', payload))?.data;
});

export const getCollectionFilters = createAsyncThunk('Collection/GetCollectionFilters', async (payload) => {
  const { post } = useRequest();
  const result = (await post('Collection/GetCollectionFilters', payload))?.data;
  return result;
});

export const mergeAttribute = createAsyncThunk('CollectionEntity/MergeCollectionEntityAttributes', async (payload) => {
  const { post } = useRequest();
  const result = (await post('CollectionEntity/MergeCollectionEntityAttributes', payload));
  if (result.status === 200)
    return true;
});

export const { setCollectionAttributeLoading } = collectionAttributeSlice.actions;

export const getCollectionAttributeLoading = ({ collectionAttribute }) => collectionAttribute.isLoading;
export default collectionAttributeSlice.reducer;
