// @ts-nocheck
import './index.css';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  Image,
  Input,
  Modal,
  notification,
  PageHeader,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fallBackImage } from '../../../assets/images/fallbackImage';
import { setCollectionTypeColor } from '../../utils/helper/commonHelper';
import {
  getCollectionEntityLoading,
  getCollectionEntitySearchResult,
  mergeCollectionEntities,
  searchCollectionEntity,
  setCollectionEntityLoading,
} from '../collectionEntitySlice';

type Props = {};

interface CollectionTypes {
  Movie: 'Movie';
  Place: 'Place';
  Book: 'Book';
}

enum CollectionType {
  Movie = 'Movie',
  Place = 'Place',
  Book = 'Book',
}

export const CollectionEntitityMergePage: React.FC<Props> = (_) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const TOTAL_ITEM_IN_PAGE = 10;

  const isLoading = useSelector(getCollectionEntityLoading);
  const isLoadingForSecondTable = useSelector(getCollectionEntityLoading);
  const searchResult = useSelector(getCollectionEntitySearchResult);

  const [searchKeywordForFirstTable, searchKeywordSetForFirstTable] =
    useState('');
  const [searchKeywordForSecondTable, searchKeywordSetForSecondTable] =
    useState('');
  const [dataOfFirstTable, setDataOfFirstTable] = useState([]);
  const [dataOfSecondTable, setDataOfSecondTable] = useState([]);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [baseEntityId, setBaseEntityId] = useState(0);
  const [baseEntity, setBaseEntity] = useState({});
  const [mergingEntityIds, setMergingEntityIds] = useState([0]);
  const [mergingEntities, setMergingEntities] = useState([]);
  const [activePanelKey, setActivePanelKey] = useState('mergingOne');
  const [firstTableValues, setFirstTableValues] = useState({
    query: '',
    take: '',
    collectionType: CollectionType.Place,
  });

  const showTotal = () =>
    dataOfFirstTable.totalCount
      ? `Total ${dataOfFirstTable.totalCount} items`
      : '';

  const specifyCollectionTypes = () => {
    return {
      movieCount: dataOfFirstTable.items?.filter(
        (i) => i.collectionType === 'Movie'
      ).length,
      placeCount: dataOfFirstTable.items?.filter(
        (i) => i.collectionType === 'Place'
      ).length,
      bookCount: dataOfFirstTable.items?.filter(
        (i) => i.collectionType === 'Book'
      ).length,
    };
  };

  const { Panel } = Collapse;

  useEffect(() => {
    console.log(searchResult);
    // setData(searchResult)
  }, [searchResult]);

  const [tableColumns] = useState([
    {
      title: 'Id',
      dataIndex: 'entityId',
      key: 'key',
      render: (_, record) => (
        <Tag
          key={`row_id_${record.id}`}
          color={setCollectionTypeColor(record.collectionType)}
        >
          #{record.entityId}
        </Tag>
      ),
    },
    {
      title: 'Love/Wanna',
      dataIndex: 'love-wannaCount',
      render: (_: any, record) => `${record.loveCount}/${record.wannaCount}`,
    },
    {
      title: 'Cover Image',
      dataIndex: 'coverImage',
      width: 150,
      render: (_: any, record) => {
        return (
          <Image
            height={100}
            width={100}
            style={{ objectFit: 'cover' }}
            key={`row_image_${record.id}`}
            src={`${record.coverImage}?width=200`}
            fallback={fallBackImage}
            preview={{
              src: record.coverImage?.length > 0 ? record.coverImage : '',
            }}
          />
        );
      },
    },
    {
      title: 'Header Metadata',
      dataIndex: 'title',
      width: 400,
      render: (_: any, record) => (
        <div key={`row_title${record.id}`}>
          <p>
            <b>Title:</b> {record.title}
          </p>
          <p>
            <b>Sub Title:</b>
            <Tooltip
              title={record.subTitle}
              overlayInnerStyle={{ width: '25em' }}
              color={setCollectionTypeColor(record.collectionType)}
            >
              {record.subTitle?.substr(0, 300) +
                (record.subTitle?.length > 300 ? '...' : '')}{' '}
            </Tooltip>
          </p>
        </div>
      ),
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      width: 250,
      render: (_: any, record) => (
        <div key={`row_operations_${record.id}`}>
          <Button
            key={`details_${record.entityId}`}
            onClick={() =>
              history.push(`/collection-entities/detail/${record.entityId}`)
            }
            type="primary"
            size="small"
          >
            Details
          </Button>
          <a
            href={`/collection-entities/edit/${record.entityId}`}
            target="_blank"
            style={{
              padding: '3px 5px',
              margin: '5px',
              border: '1px solid #0366d6',
              borderRadius: '2px',
            }}
            key={`edit${record.entityId}`}
            rel="noreferrer"
          >
            Edit in new tab
          </a>
        </div>
      ),
    },
  ]);

  const onSearchForFirstTable = async (
    values,
    skipCount = 0,
    pageSize = TOTAL_ITEM_IN_PAGE
  ) => {
    await dispatch(setCollectionEntityLoading(true));
    const request: {
      query: string;
      skip: number;
      take: number;
      collectionType: CollectionTypes;
    } = {
      query: values.query || '',
      skip: skipCount * pageSize < 0 ? 0 : skipCount * pageSize,
      take: +values.take || pageSize,
      sortingType: 'CollectionEntityCountDesc',
    };

    setFirstTableValues(values);
    console.log('values of first table', values);

    request.collectionType = values.collectionType;
    const response = await dispatch(searchCollectionEntity(request));

    if (response?.payload?.items?.length > 0) {
      const modified = [];
      response?.payload?.items.forEach((i) => {
        i.key = i.entityId;
        modified.push(i);
      });

      setDataOfFirstTable({
        items: modified,
        totalCount: response.payload.totalCount,
      });

      searchKeywordSetForFirstTable(values);
    } else {
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${values.query} `,
        key: values.query,
      });
    }

    await dispatch(setCollectionEntityLoading(false));
  };

  const onSearchForSecondTable = async (
    values,
    skipCount = 0,
    pageSize = TOTAL_ITEM_IN_PAGE
  ) => {
    await dispatch(setCollectionEntityLoading(true));
    const request: {
      query: string;
      skip: number;
      take: number;
      collectionType: CollectionTypes;
    } = {
      query: values.query || firstTableValues.query || '',
      skip: skipCount * pageSize < 0 ? 0 : skipCount * pageSize,
      take: +values.take || firstTableValues.take || pageSize,
      sortingType: 'CollectionEntityCountDesc',
    };

    request.collectionType =
      values.collectionType || firstTableValues.collectionType;
    const response = await dispatch(searchCollectionEntity(request));

    if (response?.payload?.items?.length > 0) {
      const modified = [];
      response?.payload?.items.forEach((i) => {
        i.key = i.entityId;
        modified.push(i);
      });

      console.log('baseEntity.entityId', baseEntity.entityId);

      setDataOfSecondTable({
        items: modified.filter((i) => i.entityId !== baseEntity.entityId),
        totalCount: response.payload.totalCount,
      });

      searchKeywordSetForSecondTable(values);
    } else {
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${values.query} `,
        key: values.query,
      });
    }

    await dispatch(setCollectionEntityLoading(false));
  };

  const confirm = () => {
    Modal.confirm({
      width: 800,
      title: 'Merging in progress',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          These entities will be merged <hr />
          <div>
            <h6>
              #{baseEntity.entityId} - {baseEntity.title} -{' '}
              {baseEntity.subTitle}, ❤️{baseEntity.loveCount} 🔖
              {baseEntity.wannaCount}
            </h6>
            <ol>
              {mergingEntities.map((i: any) => (
                <li key={i.entityId}>
                  #{i.entityId} - {i.title} - {i.subTitle}, ❤️{i.loveCount} 🔖
                  {i.wannaCount}
                </li>
              ))}
            </ol>
          </div>
        </div>
      ),
      okText: 'Yes, continue',
      cancelText: 'No, cancel',
      onOk: onSubmitMergingProcess,
    });
  };

  const onSubmitMergingProcess = async () => {
    console.log('merging', baseEntityId, mergingEntityIds);
    let mergeResponse = await dispatch(
      mergeCollectionEntities({
        id: baseEntityId,
        MergeIds: mergingEntityIds,
      })
    );
    console.log('mergeResponse', mergeResponse);
    if (mergeResponse.payload?.errors?.length > 0) {
      notification.error({
        message: 'API Response: Error',
        description: `Error while merging entities: ${mergeResponse.payload.errors
          ?.map((i) => i)
          .join(',')} `,
        key: mergeResponse.payload.errors.map((i) => i).join(','),
      });
    } else {
      notification.success({
        message: 'Success',
        duration: 0,
        description: `Entities merged successfully with the base entity: ${
          baseEntity.title
        } and merging entities: ${mergingEntities
          .map((i) => `${i.title} - ${i.entityId}`)
          .join(',')}`,
        key: 'success',
      });

      const withoutMergedOnes = dataOfSecondTable.items.filter(
        (i) => !mergingEntityIds.includes(i.entityId)
      );

      //setDataOfFirstTable(withoutMergedOnes);
      setDataOfFirstTable([]);
      setDataOfSecondTable([]);

      form.resetFields();
      form2.resetFields();

      setBaseEntityId(0);
      setBaseEntity({});

      setMergingEntities([]);
      setMergingEntityIds([]);

      setActivePanelKey('mergingOne');
      setFirstTableValues({});
    }
  };
  const onPaginationChangeFirstTable = (currentPage, pageSize) => {
    onSearchForFirstTable(
      searchKeywordForFirstTable,
      currentPage - 1,
      pageSize
    );
  };

  const onPaginationChangeSecondTable = (currentPage, pageSize) => {
    onSearchForSecondTable(
      searchKeywordForSecondTable,
      currentPage - 1,
      pageSize
    );
  };

  const firstTableRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      Modal.confirm({
        title:
          'Select entities to merge from new section. New search is available',
        onOk: () => {
          setBaseEntityId(selectedRows[0].entityId);
          setBaseEntity(selectedRows[0]);
          setActivePanelKey('mergingTwo');
        },
      });
      // filter selectedOne
      const _filtered = dataOfFirstTable?.items.filter(
        (i) => i.entityId != selectedRows[0].entityId
      );

      setDataOfSecondTable({
        items: _filtered,
        totalCount: dataOfFirstTable.totalCount,
      });
      console.log(dataOfFirstTable);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const secondTableRowSelections = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMergingEntityIds(selectedRows.map((i) => i.entityId));
      setMergingEntities(selectedRows);
    },
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Merge Entities"
      />

      <Row>
        <Col md={24} lg={24}>
          <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
            <Collapse
              defaultActiveKey={'mergingOne'}
              activeKey={activePanelKey}
              onChange={(key) => setActivePanelKey(key)}
            >
              <Panel
                key="mergingOne"
                header={`Select base item ${
                  baseEntityId
                    ? baseEntity.title +
                      ' - #' +
                      baseEntity.entityId +
                      ' - ' +
                      baseEntity.subTitle +
                      ' ❤️' +
                      baseEntity.loveCount +
                      ' 🔖' +
                      baseEntity.wannaCount
                    : ''
                }`}
              >
                <div
                  className="site-layout-background"
                  style={{ padding: 24, textAlign: 'center' }}
                >
                  <Row style={{ marginBottom: 24 }}>
                    <Col span="24">
                      <Form
                        layout={'inline'}
                        form={form}
                        size={'small'}
                        labelWrap
                        labelAlign="left"
                        colon={true}
                        onFinish={(key) => onSearchForFirstTable(key)}
                      >
                        <Form.Item
                          label="Collection Type"
                          name="collectionType"
                        >
                          <Select
                            defaultValue={'All'}
                            placeholder="Select Collection Type"
                          >
                            <Select.Option value="Place">Place</Select.Option>
                            <Select.Option value="Movie">Movie</Select.Option>
                            <Select.Option value="Book">Book</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Query" name="query">
                          <Input placeholder="Searching Query" />
                        </Form.Item>
                        <Form.Item
                          style={{ width: '100px' }}
                          label="take"
                          name="take"
                        >
                          <Input
                            type="number"
                            defaultValue={10}
                            min={0}
                            placeholder={10}
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button htmlType="submit" type="primary">
                            Search for base entity
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Table
                    loading={isLoading}
                    columns={tableColumns}
                    dataSource={dataOfFirstTable.items}
                    pagination={false}
                    rowSelection={{
                      type: 'radio',
                      ...firstTableRowSelection,
                    }}
                  />
                  <Pagination
                    size="small"
                    total={dataOfFirstTable.totalCount}
                    showTotal={showTotal}
                    showQuickJumper
                    onChange={(currentPage, pageSize) =>
                      onPaginationChangeFirstTable(currentPage, pageSize)
                    }
                  />
                </div>
              </Panel>

              <Panel header={'Select items to be merged'} key="mergingTwo">
                <div
                  className="site-layout-background"
                  style={{ padding: 24, textAlign: 'center' }}
                >
                  <Row style={{ marginBottom: 24 }}>
                    <Col span="24">
                      <Form
                        layout={'inline'}
                        form={form2}
                        size={'small'}
                        labelWrap
                        labelAlign="left"
                        colon={true}
                        onFinish={(key) => onSearchForSecondTable(key)}
                      >
                        <Form.Item
                          label="Collection Type"
                          name="collectionType"
                        >
                          <Select
                            placeholder="Select Collection Type"
                            defaultValue={
                              firstTableValues.collectionType || 'All'
                            }
                          >
                            <Select.Option value="Place">Place</Select.Option>
                            <Select.Option value="Movie">Movie</Select.Option>
                            <Select.Option value="Book">Book</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item label="Query" name="query">
                          <Input
                            placeholder={
                              firstTableValues.query || 'Searching Query'
                            }
                            defaultValue={firstTableValues.query}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: '100px' }}
                          label="take"
                          name="take"
                        >
                          <Input
                            type="number"
                            defaultValue={firstTableValues.take || 10}
                            min={0}
                            placeholder={10}
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button htmlType="submit" type="primary">
                            Search
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Table
                    loading={isLoadingForSecondTable}
                    columns={tableColumns}
                    dataSource={dataOfSecondTable.items}
                    pagination={false}
                    rowSelection={{
                      type: 'checkbox',
                      ...secondTableRowSelections,
                    }}
                  />
                  <Pagination
                    size="small"
                    total={dataOfSecondTable.totalCount}
                    showTotal={showTotal}
                    showQuickJumper
                    onChange={(currentPage, pageSize) =>
                      onPaginationChangeSecondTable(currentPage, pageSize)
                    }
                  />
                </div>
              </Panel>
            </Collapse>
          </Content>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={24} lg={24}>
          <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
            <div className="submit_section">
              <Button
                type="default"
                hidden={!mergingEntityIds.filter((i) => i > 0).length}
                onClick={confirm}
              >
                Merge selected{' '}
                {mergingEntityIds.filter((i) => i > 0).length + 1} Items
              </Button>
            </div>
          </Content>
        </Col>
      </Row>
    </div>
  );
};
