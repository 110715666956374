import { Button, Card, Col, Form, Input, notification, PageHeader, Row, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRequest } from '../../utils/hooks/requestHook';
import { getAuthIsLoading } from '../authSlice';

const EditorPreferences: React.FC = () => {
  const isLoading = useSelector(getAuthIsLoading) || false;
  const history = useHistory();
  const [form] = Form.useForm();

  const MIN_PASSWORD_LENGTH = 6;
  const MAX_PASSWORD_LENGTH = 9;
  const { post } = useRequest();

    interface formValues {
        id: number,
        OldPassword: string,
        Password: string,
        NewPassword: string
    }

    // interface ApiErrorResponse {
    //   errors: string[],
    //   message: string
    // }

    const onFormFinish = async (values: formValues) => {
      await post('Editor/ResetPassword', values)
        .then(result => {
          notification.success({
            message: 'Success',
            description: ('Your password has been changed.')
          });
        })
        .catch(error => {
          notification.error({
            message: 'Service Error',
            description: (error.message || 'Something unexpected happen. Api returned error. See console for details.')
          });
          console.error(error);
        });
    };

    const onFormFinishFailed = async () => {
      notification.error({ message: 'Please provide correct informations' });
    };

    return (<div>
      <div>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title="Editor Preferences"
        />
        <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
          <Card bordered>
            <Spin spinning={isLoading}>
              <Row>
                <Col span={12}>
                  <Form
                    name="preferencesOfCurrentEditor"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onFinish={onFormFinish}
                    onFinishFailed={onFormFinishFailed}
                  >
                    <Form.Item
                      label="id"
                      name="id"
                      hidden
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Old Password"
                      name="OldPassword"
                      rules={[{ required: true, message: 'Please enter your current password' }]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="Password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your new password!'
                        },
                        {
                          max: MAX_PASSWORD_LENGTH,
                          message: `Value should be less than ${MAX_PASSWORD_LENGTH} character`
                        },
                        {
                          min: MIN_PASSWORD_LENGTH,
                          message: `Value should be less than ${MIN_PASSWORD_LENGTH} character`
                        }
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="NewPassword"
                      label="Confirm Password"
                      dependencies={['Password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your new password!'
                        },
                        {
                          max: MAX_PASSWORD_LENGTH,
                          message: `Value should be less than ${MAX_PASSWORD_LENGTH} character`
                        },
                        {
                          min: MIN_PASSWORD_LENGTH,
                          message: `Value should be less than ${MIN_PASSWORD_LENGTH} character`
                        },
                        ({ getFieldValue }) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('Password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="primary" htmlType="submit" loading={isLoading}>
                                            Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Spin>
          </Card>
        </Content>
      </div>
    </div>
    );
};

export default EditorPreferences;
