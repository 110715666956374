// @ts-nocheck
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, notification, PageHeader, Pagination, Row, Select, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { numFormatter } from '../../utils/helper/numberHelpber';
import {
  deleteCollectionAttribute,
  searchCollectionAttribute,
  setCollectionAttributeLoading
} from '../collectionAttributeSlice';

type Props = {};
interface formData {
    changedFields: any,
    query: any,
    collectionType: any,
    collectionAttributeType: any,
    setFormData: () => {}
}

interface Item {
    id: number,
    isActive: boolean,
    collectionEntityCount: number
}

export const CollectionAttributeSearchPage: React.FC<Props> = (props) => {
  const TOTAL_ITEM_IN_PAGE = 10;

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<formData>();
  const [totalCount, totalCountSet] = useState(0);

  const showTotal = () => totalCount ? `Total ${totalCount} items` : '';

  const [tableColumns] = useState([
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record) => <Tag color="#108ee9">#{record.id}</Tag>
    },
    {
      title: 'Name',
      dataIndex: 'value'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Collection Entity Count',
      dataIndex: 'collectionEntityCount',
      render: (_: any, record: Item) => <Tag color="blue">{numFormatter(record.collectionEntityCount)}</Tag>
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (_: any, record: Item) => <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'Active' : 'Passive'}</Tag>
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      render: (_: any, record: Item) => <div>
        <Button onClick={() => history.push(`/collection-attributes/edit/${record.id}`)} type="primary" size="small">Edit</Button>
        <Button style={{ marginLeft: 10 }} onClick={() => onDelete(record.id)} type="primary" size="small">Delete</Button>
      </div>
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onFieldsChange = (changedFields) => {
    setFormData({ ...formData, changedFields });
  };

  const onFinish = async (values, skipCount = 0, pageSize = TOTAL_ITEM_IN_PAGE) => {
    setLoading(true);
    const response = (await dispatch(searchCollectionAttribute({ ...values, skip: (skipCount * pageSize) < 0 ? 0 : skipCount * pageSize, take: pageSize, SortingType: 'NameAscending' }))).payload;

    /*
TODO: Change response object and check the api result
        if(response.status != 200){
            setLoading(false);
            notification.open({
                message: 'Server Error',
                description: `Server returned no result. Http code is ${response.status} `,
              });
            return
        }
*/
    if (response?.items?.length) {
      setData(response.items ?? []);
      totalCountSet(response.totalCount);
      setLoading(false);
    } else {
      setLoading(false);
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${values.query} `,
        key: values
      });
    }
  };

  const onDelete = (id) => {
    Modal.confirm({
      title: 'Do you Want to delete this entity?',
      icon: <ExclamationCircleOutlined />,
      async onOk () {
        try {
          await dispatch(setCollectionAttributeLoading(true));
          await dispatch(deleteCollectionAttribute(id));
          form.submit();
          await dispatch(setCollectionAttributeLoading(false));
          message.success('Success');
        } catch (e) {
          dispatch(setCollectionAttributeLoading(false));
          message.error(e.message);
        } finally {
          setLoading(false);
        }
      },
      onCancel () {
        console.log('Cancel');
      }
    });
  };

  const onPaginationChange = (currentPage, pageSize) => {
    if (!Object.keys(formData).length) {
      notification.warn({
        message: 'No Parameters',
        description: 'Provide parameters to search for'
      });
      return;
    }

    const request = {
      query: formData.query || formData.changedFields.query,
      collectionAttributeType: formData.collectionAttributeType,
      collectionType: formData.collectionType
    };
    onFinish(request, currentPage - 1, pageSize);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Collection Attributes"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
          <Row style={{ marginBottom: 24 }}>
            <Form
              layout="inline"
              form={form}
              style={{ width: '100%' }}
              initialValues={formData}
              onValuesChange={onFieldsChange}
              onFinish={onFinish}
            >
              <Form.Item name="collectionType"
                label="Collection Type"
                style={{ width: 250 }}
                rules={[{
                  required: true, message: 'Please input entity collection type'
                }]}>
                <Select onChange={(value) => {
                  setFormData({ ...formData, collectionType: value });
                }}>
                  <Select.Option value="Place">Place</Select.Option>
                  <Select.Option value="Movie">Movie</Select.Option>
                  <Select.Option value="Book">Book</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="collectionAttributeType"
                style={{ width: 350 }}
                label="Collection Attribute Type"
                rules={[{
                  required: true, message: 'Please input entity collection attribute type'
                }]}>
                <Select onChange={(value) => {
                  setFormData({ ...formData, collectionAttributeType: value });
                }}>
                  <Select.Option value="Country">Country</Select.Option>
                  <Select.Option value="City">City</Select.Option>
                  <Select.Option value="County">County</Select.Option>
                  <Select.Option value="PlaceTypes">PlaceTypes</Select.Option>
                  <Select.Option value="Genre">Genre</Select.Option>
                  <Select.Option value="Director">Director</Select.Option>
                  <Select.Option value="Author">Author</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="query"
                label="Query"
                style={{ width: 250 }}
                rules={[{
                  required: true, message: 'Please insert a key'
                }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={loading} type="primary">Submit</Button>
              </Form.Item>
              <Form.Item>
                <Button htmlType="reset" loading={loading} onClick={() => {
                  form.resetFields();
                  setFormData(Object);
                }} type="default">Reset</Button>
              </Form.Item>
            </Form>
          </Row>
          <Table loading={loading} columns={tableColumns} dataSource={data} pagination={false} />
          <Pagination
            size="small"
            total={totalCount}
            showTotal={showTotal}
            showQuickJumper
            onChange={(currentPage, pageSize) => onPaginationChange(currentPage, pageSize)}
          />
        </div>
      </Content>
    </div>
  );
};
