// @ts-nocheck
import { Button, Result } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AddCollectionToUserModal } from '../components/AddCollectionToUserModal';
export const CollectionEntitySuccessComponent = ({ collectionEntity, entityId }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const onOk = () => {
    setVisible(false);
    history.push(`/collection-entities/detail/${entityId}`);
  };
  return (
    <React.Fragment>
      <Result
        status="success"
        title={`Successfully: ${collectionEntity.title}`}
        subTitle={`Id: ${entityId} Not yet listed in search results.`}
        extra={[
          <Button type="primary"  href={`/collection-entities/edit/${entityId}`} key="edit">Edit Collection</Button>,
          <Button onClick={() => setVisible(true)} key="add_to_user">Add To User</Button>,
          <Button href={`/collection-entities/detail/${entityId}`} key="detail">See Entity Detail</Button>,
          <Button key="console" onClick={() => history.push('/collection-entities/search')}>
                New Search
          </Button>,
        ]}
      />
      <AddCollectionToUserModal collectionEntityId={entityId}
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)} />
    </React.Fragment>
  );
};
