// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: {
    isLoading: false
  },
  reducers: {
    setCmsLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    }
  }
});

export const { setCmsLoading } = cmsSlice.actions;

export const getCmsLoading = ({ cms }) => cms.isLoading;

export const fetchReportedContents = createAsyncThunk('cms/fetch_reported_contents', async (payload) => {
  const { post } = useRequest();
  return (await post('Report/SearchReports', payload))?.data;
});

export const removeReportedContent = createAsyncThunk('cms/remove_reported_content', async (payload) => {
  const { deleteRequest } = useRequest();
  switch (payload.type) {
  case 'Comment':
    return (await deleteRequest(`Report/DeleteReportedComment?id=${payload.id}`))?.data;
  case 'UserMedia':
    return (await deleteRequest(`Report/DeleteReportedUserMedia?id=${payload.id}`))?.data;
  case 'Quote':
    return (await deleteRequest(`Report/DeleteReportedQuote?id=${payload.id}`))?.data;
  case 'User':
    return (await deleteRequest(`Report/SuspendReportedUser?id=${payload.id}`))?.data;
  case 'CollectionEntityMedia':
    return (await deleteRequest(`Report/DeleteReportedCollectionEntityMedia?id=${payload.id}`))?.data;
  }
});

export const dismissReportedContent = createAsyncThunk('cms/dismiss_reported_content', async (payload) => {
  const { post } = useRequest();
  return (await post('Report/DismissReportedContent', payload))?.data;
});

export default cmsSlice.reducer;
