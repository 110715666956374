// @ts-nocheck
import '../auth.css';

import { Button, Col, Form, Input, notification, Row, Spin } from 'antd';
import React from 'react';
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';

import HooptLogo from '../../../assets/images/logo_v2.png';
import { authKey } from '../../utils/hooks/requestHook';
import { getAuthIsLoading, setAccessToken, setAuthLoading, signIn } from '../authSlice';

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getAuthIsLoading) ?? false;

  const onFinish = async (values: any) => {
    dispatch(setAuthLoading(true));
    const val = await dispatch(signIn(values));

    if (val?.error) {
      notification.error({
        message: val?.error?.message
      });
      dispatch(setAuthLoading(false));
      return;
    }

    // @ts-ignore
    localStorage.setItem(authKey, val.payload.token);
    // @ts-ignore
    dispatch(setAccessToken(val.payload.token));
    dispatch(setAuthLoading(false));
    // @ts-ignore
    window.top.location.reload();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="auth-container">
      <div className="logo">
        <img src={HooptLogo} />
      </div>
      <Spin spinning={isLoading}>
        <Row style={{ marginTop: 30 }}>
          <Col span="8"></Col>
          <Col span="8">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="E-mail"
                name="usernameOrEmail"
                rules={[{ required: true, message: 'Please input your e-mail!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                                    Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span="8"></Col>
        </Row>
      </Spin>
    </div>
  );
};
