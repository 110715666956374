// @ts-nocheck
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, notification, PageHeader, Row, Select, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CollectionAttributeTypes from '../../../dto/CollectionAttributeTypes';
import { numFormatter } from '../../utils/helper/numberHelpber';
import {
  getCollectionFilters, mergeAttribute, searchCollectionAttribute
} from '../collectionAttributeSlice';

type Props = {};
interface formData {
    changedFields: any,
    query: any,
    collectionType: any,
    collectionAttributeType: any,
    setFormData: () => {}
}

interface Item {
    id: number,
    isActive: boolean,
    collectionAttributeCount: number
}

export const AttributeMerge: React.FC<Props> = (props) => {
  const TOTAL_ITEM_IN_PAGE = 100;

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [formData, setFormData] = useState<formData>();
  const [formOfDetectSectionData, setFormOfDetectSectionData] = useState<formData>();
  const [totalCount, totalCountSet] = useState(0);
  const [totalCountOfDetect, setTotalCountOfDetect] = useState(0);
  const [collectionAttributeTypeForSearch, setCollectionAttributeTypeForSearch] = useState<string>();
  const [showSecondTable, setShowSecondTable] = useState<'none' | 'block'>('none');
  const [showFirstTable, setShowFirstTable] = useState<'none' | 'block'>('block');
  const [collectionAttributeTypeSelectVisibility, setCollectionAttributeTypeSelectVisibility] = useState<'none' | 'block'>('none');
  const [baseAttribute, setBaseAttribute] = useState();
  const [mergingEntities, setMergingEntities] = useState();
  const [mergingBaseEntity, setMergingBaseEntity] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showSubTables, setShowSubTables] = useState(false);
  const [secondTableData, setSecondTableData] = useState([]);
  const [filteredAttribute, setFilteredAttribute] = useState([]);
  const [subAttributesVisibility, setSubAttributesVisibility] = useState<'none' | 'block'>('none');
  const [showChildAttributeModal, setShowChildAttributeModal] = useState(false);
  const [showParentAttributeModal, setShowParentAttributeModal] = useState({});

  const [tableColumns] = useState([
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record) => <Tag color="#108ee9">#{record.id}</Tag>,
      key: 'key'
    },
    {
      title: 'Name',
      dataIndex: 'value'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Collection Entity Count',
      dataIndex: 'collectionEntityCount',
      render: (_: any, record: Item) => <Tag color="blue">{numFormatter(record.collectionEntityCount)}</Tag>
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      render: (_: any, record: Item) => <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'Active' : 'Passive'}</Tag>
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      render: (_: any, record: Item) => {
        return <>
          {record.childCollectionAttributes?.length ? <Button type="primary" onClick={() => setShowChildAttributeModal(record)}> See Child Attributes </Button> : <></>}
        </>;
      }
    }
  ]);

  const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

  const [filteredAttributeTable] = useState([
    {
      title: 'Id',
      dataIndex: 'id',
      render: (_, record) => <Tag color="#108ee9">#{record.id}</Tag>,
      key: 'key'
    },
    {
      title: 'Name',
      dataIndex: 'value',
      sorter: (a, b) => {
        return collator.compare(a.value, b.value);
      },
      sortDirections: ['descend']
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Coll. Entity / Love / Wanna / Total',
      sorter: (a, b) => a.totalCount - b.totalCount,
      render: (_: any, record: Item) =>
        <>
          {record.collectionEntityCount > 0 && <Tag color="blue">{numFormatter(record.collectionEntityCount)}</Tag>}
          {record.loveCount > 0 && <Tag color="red">{numFormatter(record.loveCount)}</Tag>}
          {record.wannaCount > 0 && <Tag color="green">{numFormatter(record.wannaCount)}</Tag>}
          {record.totalCount > 0 && <Tag color="purple">{numFormatter(record.totalCount)}</Tag>}
        </>
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      render: (_: any, record: Item) => <a title='Attribute details will open in new page' target='_blank' rel='noreferrer' href={'/collection-attributes/detail/' + record.id}>Edit</a>
    }
  ]);

  const onFieldsChange = (changedFields) => {
    setFormData({ ...formData, changedFields });
  };

  const handleAttrChange = async (value: { value: string; label: React.ReactNode }) => {
    if (!value) {
      return;
    }

    setCollectionAttributeTypeForSearch(value);

    const request = {
      collectionType: formData.collectionType,
      selectedAttributeIds: (new Array(1)).fill(baseAttribute.id),
      responseAttributeTypes: (new Array(1)).fill(value),
      sortingType: 'CollectionEntityCountAsc'
    };

    const response = (await dispatch(getCollectionFilters(request))).payload;

    if (!response?.length) {
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${value} of ${baseAttribute.value}`,
        key: value + baseAttribute.type
      });
    }

    setShowSecondTable('block');
    setShowFirstTable('none');
    setSubAttributesVisibility('block');

    if (response?.length) {
      const modified = [];
      response.forEach(i => {
        i.key = i.id;
        modified.push(i);
      });

      setFilteredAttribute(modified || []);
      setLoading(false);
    }
  };

  const populateAttributeTypes = (attrType) => {
    switch (attrType) {
    case CollectionAttributeTypes.Country:
    case CollectionAttributeTypes.City:
    case CollectionAttributeTypes.County:
    case CollectionAttributeTypes.PlaceTypes:
      return <Select allowClear onChange={handleAttrChange} style={{ width: '14em' }}>
        <Select.Option disabled={attrType === CollectionAttributeTypes.Country} value="Country">Country</Select.Option>
        <Select.Option value="City">City</Select.Option>
        <Select.Option value="County">County</Select.Option>
      </Select>;

    case CollectionAttributeTypes.Director:
    case CollectionAttributeTypes.Genre:
      return <Select allowClear onChange={handleAttrChange} style={{ width: '14em' }}>
        <Select.Option value="Genre">Genre</Select.Option>
        <Select.Option value="Director">Director</Select.Option>
      </Select>;

    case CollectionAttributeTypes.Author:
      return <Select allowClear onChange={handleAttrChange} style={{ width: '14em' }}>
        <Select.Option value="Author">Author</Select.Option>
      </Select>;
    }
  };

  const onFieldsChangeDetectSection = (changedFields) => {
    setFormOfDetectSectionData({ ...formOfDetectSectionData, changedFields });
  };

  const onFinish = async (values, skipCount = 0, pageSize = TOTAL_ITEM_IN_PAGE) => {
    setLoading(true);
    const response = (await dispatch(searchCollectionAttribute({ ...values, skip: (skipCount * pageSize) < 0 ? 0 : skipCount * pageSize, take: pageSize }))).payload;

    if (response?.items?.length) {
      const modified = [];
      response?.items.forEach(i => {
        i.key = i.id;
        modified.push(i);
      });

      setData(modified || []);
      totalCountSet(response.totalCount);
      setLoading(false);
    } else {
      setLoading(false);
      notification.open({
        message: 'No Data',
        description: `No result found with the given query: ${values.query}`,
        key: values
      });
    }
  };

  const onSubmit = async () => {
    const request = {
      id: mergingBaseEntity.id,
      mergeIds: mergingEntities.map(i => i.id)
    };

    const proceedToSubmit = async () => {
      const response = (await dispatch(mergeAttribute(request))).payload;
      // Backendden gelen isteğe göre notifi göster ya da gösterme

      if (response)
        notification.open({
          message: 'Merging completed',
          description: (<div>These entities are merged <hr /> <div> <h6>{mergingBaseEntity.value}</h6> <ol>{mergingEntities.map((i: any) => <li key={i.value} >{i.value}</li>)}</ol></div></div>),
          key: mergingEntities[0].id
        });
      else
        notification.error({
          message: 'Merging is not completed',
          description: 'Operation has errors and cannot be completed',
          key: mergingEntities[0].id
        });
    };

    const confirm = () => {
      Modal.confirm({
        title: 'Merging in progress',
        icon: <ExclamationCircleOutlined />,
        content: (<div>These attributes will be merged <hr /> <div> <h6>{mergingBaseEntity.value}</h6> <ol>{mergingEntities.map((i: any) => <li key={i.value} >{i.value}</li>)}</ol></div></div>),
        okText: 'Yes, continue',
        cancelText: 'No, cancel',
        onOk: proceedToSubmit
      });
    };
    confirm();
  };

  const firstTableRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBaseAttribute(selectedRows[0]);
      // setTimeout(() => document.getElementById('secondTable').scrollIntoView({ behavior: 'smooth' }), 100)
      setCollectionAttributeTypeSelectVisibility('block');
      console.log(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  };

  const filteredAttributeRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMergingBaseEntity(selectedRows[0]);
    }
  };

  const secondTableRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMergingEntities(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  };

  const onSecondFormSearch = async (values, skipCount = 0, pageSize = TOTAL_ITEM_IN_PAGE) => {
    values.collectionAttributeType = collectionAttributeTypeForSearch;

    setLoading(true);
    const response = (await dispatch(searchCollectionAttribute({ ...values, skip: (skipCount * pageSize) < 0 ? 0 : skipCount * pageSize, take: pageSize }))).payload;

    if (response?.items?.length) {
      const modified = [];
      response?.items.forEach(i => {
        i.key = i.id;
        modified.push(i);
      });

      setSecondTableData(modified || []);
      setLoading(false);
    } else {
      setLoading(false);
      notification.open({
        message: `No result found with the given query: ${values.query}`,
        description: `Is a "${values.query}" a ${collectionAttributeTypeForSearch}? Check your search parameters and try again.`,
        key: values
      });
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Merge Attributes"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>

          <div id="firstTable">
            <Row style={{ marginBottom: 24 }}>
              <Form
                name="formOfSecond"
                layout="inline"
                form={form}
                style={{ width: '100%' }}
                initialValues={formData}
                onValuesChange={onFieldsChange}
                onFinish={onFinish}
              >
                <Form.Item name="collectionType"
                  label="Collection Type"
                  style={{ width: 250 }}
                  rules={[{
                    required: true, message: 'Please input attribute collection type'
                  }]}>
                  <Select onChange={(value) => {
                    setFormData({ ...formData, collectionType: value });
                  }}>
                    <Select.Option value="Place">Place</Select.Option>
                    <Select.Option value="Movie">Movie</Select.Option>
                    <Select.Option value="Book">Book</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="query"
                  label="Query"
                  style={{ width: 250 }}
                  rules={[{
                    required: true, message: 'Please insert a key'
                  }]}>
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={loading} type="primary">Submit</Button>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="reset" loading={loading} onClick={() => {
                    form.resetFields();
                    setFormData(Object);
                  }} type="default">Reset</Button>
                </Form.Item>
              </Form>
            </Row>

            <Table loading={loading}
              rowSelection={{
                type: 'radio',
                ...firstTableRowSelection
              }}
              columns={tableColumns}
              dataSource={data}
              pagination={true} />

            <Card style={{ display: collectionAttributeTypeSelectVisibility }}>
              <div>{baseAttribute?.value} has been selected</div>
              <div><p>Select a collection attribute type to search for</p><div>
                {populateAttributeTypes(baseAttribute?.type)}
              </div>
              </div>
            </Card>
          </div>

          <hr style={{ margin: '3em' }} />

          <div id="sub_attributes" style={{ display: subAttributesVisibility }}>
            <div>2 - Search result for {baseAttribute?.value}</div>
            <Table
              rowSelection={{
                type: 'radio',
                ...filteredAttributeRowSelection
              }}
              columns={filteredAttributeTable}
              dataSource={filteredAttribute}
              pagination={true} />

          </div>

          <hr style={{ margin: '3em' }} />

          <div id="secondTable" style={{ display: showSecondTable }}>
            <div>3 - Set Merging Ids</div>
            <div>Results will be filtered as {collectionAttributeTypeForSearch} </div>

            <Row>
              <Form
                layout={'inline'}
                style={{ width: '100%' }}
                form={secondForm}
                onFinish={onSecondFormSearch}
              >
                <Form.Item name="query" label="Query">
                  <Input placeholder="Type a keyword to search" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary">Search</Button>
                </Form.Item>
              </Form>

            </Row>

            <Table
              loading={loading}
              rowSelection={{
                type: 'checkbox',
                ...secondTableRowSelection
              }}
              columns={tableColumns}
              dataSource={secondTableData.filter(i => i.id !== mergingBaseEntity?.id)}
              expandable={{
                expandedRowRender: record => {
                  const dataSource = [];
                  dataSource.push(record.parentCollectionAttribute);
                  return <Card>
                    <Tag color='pink' >This table shows Parent Attribute of {record.value}</Tag>
                    <Table
                      size="small"
                      key={'subTable_' + record.id}
                      rowKey={record => 'subTable_' + record.id}
                      pagination={false}
                      dataSource={dataSource} columns={filteredAttributeTable} />
                  </Card>;
                },
                rowExpandable: record => record.parentCollectionAttribute?.id > 1
              }}
              pagination={true} />

            <div className='submit_section'>
              <Button type="default"
                hidden={!mergingEntities?.length}
                onClick={onSubmit}>
                                Merge selected {mergingEntities?.length + 1} Items
              </Button>
            </div>
          </div>
        </div>
      </Content>

      <Modal title={`See Child Attributes of ${showChildAttributeModal.value} (#${showChildAttributeModal.id}) `}
        width='70%'
        visible={showChildAttributeModal} onOk={() => setShowChildAttributeModal(false)} onCancel={() => setShowChildAttributeModal(false)}>
        <Table
          columns={filteredAttributeTable}
          dataSource={showChildAttributeModal.childCollectionAttributes}
          pagination={true} />
      </Modal>
    </div>
  );
};
