/* eslint-disable multiline-ternary */
import './index.css';

import type {InputRef} from 'antd';
import {Button, Form, Input, Popconfirm, Table} from 'antd';
import type {FormInstance} from 'antd/es/form';
import React, {useContext, useEffect, useRef, useState} from 'react';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface CastAndCrewFile {
  fileName: string
}

interface DataType {
  key: React.Key;
  name: string;
  type: string;
  character: string;
  image: CastAndCrewFile
}
interface Item {
  name: string;
  type: string;
  character: string;
  image: CastAndCrewFile
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing
      ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
      : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface CastAndCrewTableProps{
  onCastAndCrewSubmit: (tableData) => void,
  castAndCrewDatasRaw: DataType[]
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const CastAndCreTable: React.FC<CastAndCrewTableProps> = ({ onCastAndCrewSubmit, castAndCrewDatasRaw }) => {
  const [dataSource, setDataSource] = useState<DataType[]>(castAndCrewDatasRaw || []);
  const [count, setCount] = useState(2);

  const handleDelete = (key: React.Key) => {
    console.log('key is undefined', key);
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'name',
      dataIndex: 'name',
      editable: true
    },
    {
      title: 'type',
      dataIndex: 'type',
      editable: true
    },
    {
      title: 'character',
      dataIndex: 'character',
      editable: true
    },
    {
      title: 'image',
      dataIndex: 'imageUrl',
      editable: true,
      width: '15vw'
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete this record?" onConfirm={() => handleDelete(record.key)}>
            <Button> Delete </Button>
          </Popconfirm>
        )
          : null
    }
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      name: 'John Doe',
      type: 'Cast',
      character: `Jane Doe`,
      image: { fileName: 'url.jpeg' }

    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    onCastAndCrewSubmit(newData);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  return (
    <div>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Add a row
      </Button>
      {/*       <Button onClick={() => onCastAndCrewSubmit(dataSource)} type="primary" style={{ marginBottom: 16, float:'right' }}>
        Save Cast And Crew Members
      </Button> */}
      <Table
        size='small'
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        pagination={{position: ['bottomRight']}}
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </div>
  );
};

export default CastAndCreTable;
